import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { AccountAccess, TrialPlan } from '@celum/authentication';
import { CelumDirectivesModule } from '@celum/ng2base';
import { TypeUtil } from '@celum/sacc/shared';

import { AccountSelectorService } from './account-selector.service';
import { CopyToClipboardComponent } from '../copy-to-clipboard/copy-to-clipboard.component';

@Component({
  selector: 'sacc-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrls: ['./account-selector.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatSelectModule, MatTooltipModule, CelumDirectivesModule, CopyToClipboardComponent],
  providers: [AccountSelectorService],
  // Needed to customise the panel width
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountSelectorComponent implements OnInit {
  @Input()
  public redirectTargetAfterSwitch: string;
  /**
   * Optional function that allows to append a suffix to the final path after switching the account
   */
  @Input()
  public pathSuffixFn: (path: string) => string;

  @Input()
  public managedAccountsOnly = true;
  protected readonly TypeUtil = TypeUtil;

  constructor(public service: AccountSelectorService) {}

  public selectedAccountChanged(accountId: string): void {
    this.service.changeSelectedAccount(accountId);
  }

  public ngOnInit(): void {
    this.service.initialize(this.redirectTargetAfterSwitch, this.managedAccountsOnly, this.pathSuffixFn);
  }

  protected accountTracker = (i: number, a: AccountAccess | TrialPlan) => TypeUtil.getAccountAccessOrTrialPlanId(a);
}
