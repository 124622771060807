@if (service.vm$ | async; as vm) {
  <celum-icon
    class="sacc-group-filter_add-group"
    cdkOverlayOrigin
    data-test-group-filter-add-group-icon
    #originOverlay="cdkOverlayOrigin"
    [class.sacc-group-filter_add-group--disabled]="disabled"
    [configuration]="addNewGroup"
    [matTooltip]="
      disabled
        ? ('COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.DISABLED' | translate)
        : ('COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.ADD_GROUPS' | translate)
    "
    (click)="!disabled && service.toggleMenu(true)"></celum-icon>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="vm.isOpen"
    [cdkConnectedOverlayOrigin]="originOverlay"
    [cdkConnectedOverlayPanelClass]="['mat-elevation-z3', 'sacc-group-filter_search-and-select']"
    (overlayOutsideClick)="service.toggleMenu(false)">
    <celum-search-and-select
      data-test-group-filter-search-and-select
      [disabledCheckboxTooltip]="'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.MAX_NUMBER_REACHED' | translate: { groupLimit: vm.groupLimit }"
      [hasMoreBottom]="false"
      [items]="vm.data"
      [itemTemplate]="itemTemplate"
      [loadingItems]="vm.loading"
      [maxSelectableItems]="vm.groupLimit"
      [multipleSelection]="true"
      [noItemFoundIcon]="null"
      [noItemText]="'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.NO_MATCHING_GROUPS_FOUND' | translate: { searchValue: vm.searchValue }"
      [searchBoxPlaceholderText]="'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.SEARCH_FOR_GROUPS'"
      [selectedItems]="vm.selectedValues"
      (itemSelectionChanged)="selectionChanged($event, vm.selectedValues)"
      (searchValueChanged)="service.patchState({ searchValue: $event })"></celum-search-and-select>
  </ng-template>

  <ng-template #itemTemplate let-item>
    <div class="sacc-group-filter_group-item" data-test-group-item>
      <sacc-group-avatar class="sacc-group-filter_group-item-avatar" [config]="item | groupToAvatarConfig"></sacc-group-avatar>
      <div class="sacc-group-filter_group-item-data">
        @if (item.name) {
          <div class="sacc-group-filter_group-item-name" spaceAwareTooltip [matTooltip]="item.name">
            {{ item.name }}
          </div>
        }
      </div>
    </div>
  </ng-template>
}
