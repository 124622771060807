import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { filter, map, take } from 'rxjs/operators';

import { AccountAccessStatus, AccountUserRole, ExperiencePrivilege, InvitationStatus, WorkroomPrivilege } from '@celum/authentication';
import { CelumButtonModule, CelumIconModule, IconConfiguration } from '@celum/common-components';
import { isTruthy } from '@celum/core';
import { AccountMember } from '@celum/sacc/domain';
import { accountMemberActions, AppState, MemberShipTableColumns } from '@celum/sacc/shared';
import { UserAvatarComponent } from '@celum/shared/ui-people';
import { UserNamePipe } from '@celum/shared/util';

import { AccountMemberTableService } from './account-member-table.service';
import { ActivationStatusComponent } from '../activation-status/activation-status.component';
import { DeleteDeactivateAccountMemberDialogComponent } from '../delete-deactivate-account-member-dialog/delete-deactivate-account-member-dialog.component';
import { EditAccountMemberDialogComponent } from '../edit-dialogs/edit-account-member-dialog/edit-account-member-dialog.component';
import { GroupAvatarListComponent } from '../group-avatar-list/group-avatar-list.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { InvitationStatusComponent } from '../invitation-status/invitation-status.component';
import { PrivilegeIconComponent, PrivilegeIconType } from '../privilege-icon/privilege-icon.component';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { TableCountComponent } from '../table-count/table-count.component';

@Component({
  selector: 'sacc-account-member-table',
  templateUrl: './account-member-table.component.html',
  styleUrls: ['./account-member-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    InfiniteScrollModule,
    TranslateModule,

    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,

    CelumButtonModule,
    CelumIconModule,

    ActivationStatusComponent,
    GroupAvatarListComponent,
    IconButtonComponent,
    InvitationStatusComponent,
    PrivilegeIconComponent,
    SearchBarComponent,
    TableCountComponent,
    UserAvatarComponent,
    UserNamePipe
  ],
  providers: [AccountMemberTableService],
  // Needed to show overflow on the role selector
  encapsulation: ViewEncapsulation.None
})
export class AccountMemberTableComponent implements OnDestroy {
  @Input()
  public allowEditingAccountMember = false;

  @Input()
  public alwaysShowPrivileges = false;

  protected vm$ = this.service.vm$;
  protected scrollContainer = '.inner-drawer-content';

  protected AccountAccessStatus = AccountAccessStatus;
  protected AccountUserRole = AccountUserRole;

  protected icons: { [key: string]: IconConfiguration } = {
    menu: new IconConfiguration('option-m').withIconSize(28),
    delete: IconConfiguration.large('remove-l'),
    activate: IconConfiguration.medium('user-activate'),
    deactivate: IconConfiguration.medium('user-deactivate'),
    imported: IconConfiguration.medium('check-circle-m')
  };

  protected readonly ExperiencePrivilege = ExperiencePrivilege;
  protected readonly WorkroomPrivilege = WorkroomPrivilege;
  protected readonly PrivilegeIconType = PrivilegeIconType;

  private internalShowActionsMenu = true;

  private readonly allColumns = [
    MemberShipTableColumns.ProfilePicture,
    MemberShipTableColumns.Name,
    MemberShipTableColumns.Email,
    MemberShipTableColumns.Status,
    MemberShipTableColumns.Groups,
    MemberShipTableColumns.AccountMemberRole,
    MemberShipTableColumns.Imported,
    MemberShipTableColumns.Privileges,
    MemberShipTableColumns.Actions
  ];

  constructor(
    private store$: Store<AppState>,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    protected service: AccountMemberTableService,
    private dialog: MatDialog
  ) {
    this.calculateAndApplyVisibleColumns();
  }

  @Input()
  public set showActionsMenu(showActionsMenu: boolean) {
    this.internalShowActionsMenu = showActionsMenu;
    this.calculateAndApplyVisibleColumns();
  }

  public ngOnDestroy(): void {
    this.store$.dispatch(accountMemberActions.resetAccountTableFilter());
  }

  protected accountMemberTracker(_: number, accountMember: AccountMember): string {
    return accountMember.id;
  }

  protected toggleStatus(accountMember: AccountMember, event?: MatSlideToggleChange): void {
    const updateAccountMember: AccountMember = { ...accountMember };
    const activateMember = event?.checked === true ? true : updateAccountMember.status === AccountAccessStatus.INACTIVE;
    updateAccountMember.status = activateMember ? AccountAccessStatus.ACTIVE : AccountAccessStatus.INACTIVE;

    if (activateMember) {
      this.store$.dispatch(
        accountMemberActions.updateStatus({
          accountMember: updateAccountMember,
          keepScopes: false
        })
      );
      return;
    }

    const dialogConfig = AccountMemberTableComponent.buildDialogConfig();
    dialogConfig.data = {
      title: this.translateService.instant('COMPONENTS.ACCOUNT_MEMBER_TABLE.DEACTIVATE_DIALOG.TITLE'),
      description: this.translateService.instant('COMPONENTS.ACCOUNT_MEMBER_TABLE.DEACTIVATE_DIALOG.DESCRIPTION', {
        name: `${accountMember.firstName ?? ''} ${accountMember.lastName ?? ''}`.trim() || '-'
      }),
      confirmationButtonText: this.translateService.instant('COMPONENTS.ACCOUNT_MEMBER_TABLE.DEACTIVATE_DIALOG.CONFIRM'),
      cancelButtonText: this.translateService.instant('COMPONENTS.ACCOUNT_MEMBER_TABLE.DEACTIVATE_DIALOG.CANCEL'),
      type: 'warning',
      hasDriveLicense$: this.service.select(state => state.hasDriveLicense)
    };

    this.matDialog
      .open(DeleteDeactivateAccountMemberDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(shouldDeactivate => {
        if (shouldDeactivate) {
          this.store$.dispatch(
            accountMemberActions.updateStatus({
              accountMember: updateAccountMember,
              keepScopes: !!shouldDeactivate.keepScopes
            })
          );
          return;
        } else if (event) {
          event.source.checked = true;
        }
      });
  }

  protected removeAccountMember(accountMember: AccountMember): void {
    const dialogConfig = AccountMemberTableComponent.buildDialogConfig();
    const fullName = `${accountMember.firstName ?? ''} ${accountMember.lastName ?? ''}`.trim() || '-';
    dialogConfig.data = {
      title: this.translateService.instant('COMPONENTS.ACCOUNT_MEMBER_TABLE.REMOVE_DIALOG.TITLE'),
      description: this.translateService.instant('COMPONENTS.ACCOUNT_MEMBER_TABLE.REMOVE_DIALOG.DESCRIPTION', { user: fullName }),
      confirmationButtonText: this.translateService.instant('COMPONENTS.ACCOUNT_MEMBER_TABLE.REMOVE_DIALOG.CONFIRM'),
      cancelButtonText: this.translateService.instant('COMPONENTS.ACCOUNT_MEMBER_TABLE.REMOVE_DIALOG.CANCEL'),
      type: 'warning',
      hasDriveLicense$: this.service.select(state => state.hasDriveLicense)
    };

    this.matDialog
      .open(DeleteDeactivateAccountMemberDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        take(1),
        filter(shouldRemove => shouldRemove)
      )
      .subscribe(shouldRemove => {
        this.store$.dispatch(
          accountMemberActions.remove({
            accountMember,
            keepScopes: shouldRemove.keepScopes
          })
        );
      });
  }

  protected canMemberBeModified(accountMember: AccountMember): boolean {
    return (
      accountMember.status !== AccountAccessStatus.INIT || [InvitationStatus.DISAPPROVED, InvitationStatus.REJECTED].includes(accountMember.invitationStatus)
    );
  }

  protected sortData(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.store$.dispatch(accountMemberActions.sortChanged({ sort }));
  }

  protected onScroll(): void {
    this.store$.dispatch(accountMemberActions.search({ resetSearch: false }));
  }

  protected searchChanged(event: string): void {
    this.store$.dispatch(accountMemberActions.filterChanged({ filter: event }));
  }

  protected close(): void {
    this.store$.dispatch(accountMemberActions.filterChanged({ filter: '' }));
  }

  protected rowClicked(editedMember: AccountMember): void {
    this.vm$
      .pipe(
        take(1),
        map(vm => ({ accountAccess: vm.accountAccess, currentUser: vm.currentUser }))
      )
      .subscribe(({ accountAccess, currentUser }) => {
        const dialogRef = this.dialog.open(EditAccountMemberDialogComponent, {
          width: '84.8rem',
          height: '75.4rem',
          maxWidth: '84.8rem',
          restoreFocus: false,
          autoFocus: false,
          data: {
            editedMember,
            accountAccess,
            currentUser
          }
        });
        dialogRef
          .afterClosed()
          .pipe(isTruthy())
          .subscribe(() => this.store$.dispatch(accountMemberActions.search({ resetSearch: true })));
      });
  }

  private calculateAndApplyVisibleColumns(): void {
    let columns = [...this.allColumns];

    if (!this.internalShowActionsMenu) {
      columns = columns.filter(column => column !== MemberShipTableColumns.Actions);
    }

    this.service.patchState({ availableColumns: columns });
  }

  private static buildDialogConfig(): MatDialogConfig {
    return {
      autoFocus: false
    } as MatDialogConfig;
  }
}
