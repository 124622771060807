import { createSelector } from '@ngrx/store';

import { Repository } from '@celum/sacc/domain';

import { repositoryAdapter, RepositoryState } from './repository.state';
import { AppState } from '../app.state';

export const selectRepositoryState = (state: AppState) => state.repositories;

export const selectRepositoriesAll = createSelector(selectRepositoryState, repositoryAdapter.getSelectors().selectAll);

export const selectRepositoryCount = createSelector(selectRepositoryState, repositoryState => repositoryState.totalCount);

export const selectRepositoryFilteredCount = createSelector(selectRepositoryState, repositoryState => repositoryState.filterCount);

export const selectRepositoriesAllLoaded = createSelector(
  selectRepositoryState,
  selectRepositoriesAll,
  (repositoryState, repositories) => repositories.length > 0 && !repositoryState.continuationToken
);

export const selectRepositoriesLoading = createSelector(selectRepositoryState, repositoryState => repositoryState.loading);

export const selectRepositoryFilter = createSelector(selectRepositoryState, repositoryState => repositoryState.filter);

export const selectRepositoryNextBatchParams = createSelector(selectRepositoryState, repositoryState => {
  return {
    count: repositoryAdapter.getSelectors().selectAll(repositoryState).length,
    sort: repositoryState.sort,
    continuationToken: repositoryState.continuationToken
  };
});

export const selectRepositoryByRepositoryId = createSelector(
  selectRepositoryState,
  (repositoryState: RepositoryState, props: any): Repository => repositoryState.entities[props.repositoryId]
);
