import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { AccountAccess, AuthService, License, LicenseType, UserDetails, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { Account, AccountMember } from '@celum/sacc/domain';
import {
  AccountResourceService,
  AppState,
  MemberShipTableColumns,
  selectAccountActiveAccountId,
  selectAccountMemberFilter,
  selectAccountMemberFilterCount,
  selectAccountMembersAll,
  selectAccountMembersAllLoaded,
  selectAccountMembersLoading,
  selectUserCanEditActiveAccount,
  selectUserLicenseByActiveAccountIdAndLicenseType
} from '@celum/sacc/shared';

type AccountMemberTableState = {
  availableColumns: MemberShipTableColumns[];
  allAccountMembersLoaded: boolean;
  accountMembersLoading: boolean;
  hasExperienceLicense: boolean;
  hasWorkroomLicense: boolean;
  hasDriveLicense: boolean;
  canEdit: boolean;
  token: string;
  tokenExpiration: number;
  accountAccess: AccountAccess;
  accountId?: string;
  account?: Account;
};

export type AccountMemberTableViewModel = {
  searchString: string;
  accountMembers?: AccountMemberTableAccountMember[];
  displayedColumns: MemberShipTableColumns[];
  accountMemberCount: number;
  currentUser: UserDetails;
} & Omit<AccountMemberTableState, 'hasDriveLicense' | 'tokenExpiration' | 'availableColumns' | 'account'>;

export type AccountMemberTableAccountMember = AccountMember & { isSelf?: boolean; isOwner?: boolean; canEditRoles?: boolean };

@Injectable({ providedIn: 'root' })
export class AccountMemberTableService extends ComponentStore<AccountMemberTableState> {
  public vm$ = combineLatest([
    this.store$.select(selectAccountActiveAccountId),
    this.select(state => state.allAccountMembersLoaded),
    this.select(state => state.accountMembersLoading),
    this.store$.select(selectAccountMemberFilter),
    this.store$.select(selectAccountMembersAll),
    this.select(state => state.account)
  ]).pipe(
    distinctUntilChanged(),
    withLatestFrom(
      this.store$.select(selectAccountMemberFilterCount),
      this.select(state => state.canEdit),
      this.select(state => state.hasWorkroomLicense),
      this.select(state => state.hasExperienceLicense),
      this.select(state => state.token),
      this.userService.currentUser$.pipe(isTruthy())
    ),
    switchMap(
      ([
        [accountId, allAccountMembersLoaded, accountMembersLoading, searchString, accountMembers, account],
        accountMemberCount,
        canEdit,
        hasWorkroomLicense,
        hasExperienceLicense,
        token,
        currentUser
      ]) => {
        return this.createViewModel(
          allAccountMembersLoaded,
          accountMembersLoading,
          searchString,
          accountMembers,
          accountMemberCount,
          canEdit,
          hasWorkroomLicense,
          hasExperienceLicense,
          accountId,
          currentUser,
          token,
          account
        );
      }
    )
  );

  constructor(
    private store$: Store<AppState>,
    private authService: AuthService,
    private userService: UserService,
    private accountService: AccountResourceService
  ) {
    super({
      availableColumns: [],
      allAccountMembersLoaded: undefined,
      accountMembersLoading: undefined,
      hasDriveLicense: undefined,
      hasExperienceLicense: undefined,
      hasWorkroomLicense: undefined,
      canEdit: undefined,
      token: undefined,
      tokenExpiration: undefined,
      accountAccess: undefined
    });

    // Select and store values in the ComponentStore that the component (not the template) needs access to
    combineLatest([this.store$.select(selectAccountMembersAllLoaded), this.store$.select(selectAccountMembersLoading)])
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(
          this.store$.select(selectUserCanEditActiveAccount),
          this.store$.select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.WORK)).pipe(map((license: License) => license && license.active)),
          this.store$
            .select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.EXPERIENCE))
            .pipe(map((license: License) => license && license.active)),
          this.store$.select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.DRIVE)).pipe(map((license: License) => license && license.active)),
          this.select(state => state.token),
          this.userService.currentUser$.pipe(isTruthy()),
          this.store$.select(selectAccountActiveAccountId)
        ),
        switchMap(
          ([[allAccountMembersLoaded, accountMembersLoading], canEdit, hasWorkroomLicense, hasExperienceLicense, hasDriveLicense, token, user, accountId]) => {
            // Check whether we need to renew the token. We have to do this manually here because setting the link manually for avatars doesn't trigger any http interceptors,
            // so our normal token refresh mechanisms don't trigger here
            if (!token || this.get().tokenExpiration === undefined || Date.now() > this.get().tokenExpiration) {
              return this.authService.getAuthResult().pipe(
                tap(result =>
                  this.patchState({
                    tokenExpiration: result.expiresOn.getTime(),
                    token: result.accessToken
                  })
                ),
                map(() => ({
                  allAccountMembersLoaded,
                  accountMembersLoading,
                  canEdit,
                  hasWorkroomLicense,
                  hasExperienceLicense,
                  hasDriveLicense,
                  accountAccess: user.accountAccesses.find(accountAccess => accountAccess.accountId === accountId),
                  accountId
                }))
              );
            }

            return of({
              allAccountMembersLoaded,
              accountMembersLoading,
              canEdit,
              hasWorkroomLicense,
              hasExperienceLicense,
              hasDriveLicense,
              accountAccess: user.accountAccesses.find(accountAccess => accountAccess.accountId === accountId),
              accountId
            });
          }
        ),
        takeUntilDestroyed()
      )
      .subscribe(data =>
        this.patchState({
          allAccountMembersLoaded: data.allAccountMembersLoaded,
          accountMembersLoading: data.accountMembersLoading,
          hasWorkroomLicense: data.hasWorkroomLicense,
          hasExperienceLicense: data.hasExperienceLicense,
          hasDriveLicense: data.hasDriveLicense,
          canEdit: data.canEdit,
          accountAccess: data.accountAccess,
          accountId: data.accountId
        })
      );

    // Fetch information about the currently selected account. Needed if this component is used in a context where the account is not yet loaded into the store
    this.state$
      .pipe(
        filter(vm => !!vm.accountId || vm.accountId !== vm.account?.id),
        map(vm => vm.accountId),
        distinctUntilChanged(),
        isTruthy(),
        switchMap(accountId => this.accountService.getById(accountId))
      )
      .subscribe(account => {
        this.patchState({ account });
      });
  }

  private createViewModel(
    allAccountMembersLoaded: boolean,
    accountMembersLoading: boolean,
    searchString: string,
    accountMembers: AccountMember[],
    accountMemberCount: number,
    canEdit: boolean,
    hasWorkroomLicense: boolean,
    hasExperienceLicense: boolean,
    accountId: string,
    currentUser: UserDetails,
    token: string,
    account: Account
  ): Observable<AccountMemberTableViewModel> {
    let displayedColumns = this.get().availableColumns;
    if (!canEdit) {
      displayedColumns = displayedColumns.filter(column => column !== MemberShipTableColumns.Actions);
    }

    if (!account?.associatedFederations?.length) {
      displayedColumns = displayedColumns.filter(column => column !== MemberShipTableColumns.Imported);
    }

    return of({
      allAccountMembersLoaded: allAccountMembersLoaded,
      accountMembersLoading: accountMembersLoading,
      searchString,
      accountMembers: accountMembers.map(user => {
        const isOwner = currentUser.accountAccesses.find(accountAccess => accountAccess.accountId === accountId)?.ownerOid === user.oid;
        const isSelf = user.oid === currentUser.oid;
        return {
          ...user,
          isSelf,
          isOwner,
          canEditRoles: currentUser.admin ? canEdit && !isOwner && !isSelf : canEdit
        };
      }),
      accountMemberCount: accountMemberCount,
      canEdit,
      token,
      hasExperienceLicense,
      hasWorkroomLicense,
      displayedColumns,
      currentUser,
      accountAccess: currentUser.accountAccesses.find(accountAccess => accountAccess.accountId === accountId)
    });
  }
}
