import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';

import { AccountAccess, AccountUserRole, TrialPlan } from '@celum/authentication';
import { DataUtil } from '@celum/core';
import {
  accountActions,
  AppState,
  selectAccountActiveAccountId,
  selectUserActiveAccountAccesses,
  selectUserAllManagedAccountAccesses,
  selectUserSelectedAccountOrTrialPlanByActiveId,
  selectUserSelectedManagedAccountOrTrialPlanByActiveId,
  StorageUtils,
  TypeUtil,
  Utils
} from '@celum/sacc/shared';

export interface AccountSelectorViewModel {
  managedAccountsAndTrialPlans: ((AccountAccess & { finalName: string }) | (TrialPlan & { finalName: string }))[];
  name: string;
  logo: string;
  id: string;
}

@Injectable()
export class AccountSelectorService {
  public vm$: Observable<AccountSelectorViewModel>;
  protected managedAccountsAndTrialPlans$: Observable<(AccountAccess | TrialPlan)[]>;
  protected selectedItem$: Observable<AccountAccess | TrialPlan>;
  private redirectTargetAfterSwitch: string;
  private destroyRef = inject(DestroyRef);
  /**
   * Optional function that allows to append a suffix to the final path after switching the account
   */
  private pathSuffixFn: (path: string) => string;

  private wasChangedByUser = false;

  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private routerSnapshot: ActivatedRoute
  ) {}

  public initialize(redirectTargetAfterSwitch: string, mangedAccountsOnly: boolean, pathSuffixFn?: (path: string) => string): void {
    this.redirectTargetAfterSwitch = redirectTargetAfterSwitch;
    this.pathSuffixFn = pathSuffixFn;

    const accountsAndTrialPlans$ = mangedAccountsOnly
      ? this.store$.select(selectUserAllManagedAccountAccesses)
      : this.store$.select(selectUserActiveAccountAccesses);

    this.managedAccountsAndTrialPlans$ = accountsAndTrialPlans$.pipe(
      filter(accounts => accounts?.length > 0),
      map(accounts => accounts.sort((a, b) => TypeUtil.getAccountAccessOrTrialPlanName(a)?.localeCompare(TypeUtil.getAccountAccessOrTrialPlanName(b))))
    );

    this.selectedItem$ = mangedAccountsOnly
      ? this.store$.select(selectUserSelectedManagedAccountOrTrialPlanByActiveId)
      : this.store$.select(selectUserSelectedAccountOrTrialPlanByActiveId);

    this.vm$ = combineLatest([this.managedAccountsAndTrialPlans$, this.selectedItem$]).pipe(
      map(([accounts, selected]) => this.createViewModel(accounts, selected))
    );

    this.managedAccountsAndTrialPlans$
      .pipe(
        take(1),
        withLatestFrom(this.selectedItem$),
        map(([accounts, selected]) => selected || accounts[0]),
        map(account => {
          const accountId = this.routerSnapshot.snapshot.params.id || TypeUtil.getAccountAccessOrTrialPlanId(account);
          this.navigate(accountId);
          return accountActions.selectedAccountChanged({
            accountId,
            resetFilters: true,
            resetTables: true,
            skipLoadingDetails: (account as AccountAccess).role === AccountUserRole.MEMBER
          });
        }),
        tap(() => (this.wasChangedByUser = true))
      )
      .subscribe(action => this.store$.dispatch(action));

    // If navigation finished, set the selected account based on the URL once
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        const accountIdFromUrl = this.routerSnapshot.snapshot.params.id;
        if (accountIdFromUrl && !this.wasChangedByUser) {
          this.store$
            .select(selectAccountActiveAccountId)
            .pipe(take(1))
            .subscribe(accountId =>
              this.store$.dispatch(
                accountActions.selectedAccountChanged({
                  accountId: accountIdFromUrl,
                  resetFilters: accountIdFromUrl !== accountId,
                  resetTables: true,
                  skipLoadingDetails: false
                })
              )
            );
        }
        this.wasChangedByUser = false;
      });
  }

  public changeSelectedAccount(accountId: string /*account: AccountAccess | TrialPlan*/): void {
    this.wasChangedByUser = true;

    this.vm$
      .pipe(
        map(vm => vm.managedAccountsAndTrialPlans.find(account => TypeUtil.getAccountAccessOrTrialPlanId(account) === accountId)),
        take(1)
      )
      .subscribe(account => {
        this.store$.dispatch(
          accountActions.selectedAccountChanged({
            accountId,
            resetFilters: true,
            resetTables: true,
            skipLoadingDetails: (account as AccountAccess).role === AccountUserRole.MEMBER
          })
        );
        this.navigate(accountId);
      });
  }

  public getName(account: AccountAccess | TrialPlan): string {
    return TypeUtil.getAccountAccessOrTrialPlanName(account);
  }

  private navigate(id: string): void {
    if (!DataUtil.isEmpty(this.redirectTargetAfterSwitch) && !DataUtil.isEmpty(id)) {
      let finalPath = `/${this.redirectTargetAfterSwitch}/${id}`;
      if (this.pathSuffixFn) {
        finalPath = this.pathSuffixFn(finalPath);
      }
      this.router.navigateByUrl(finalPath);
    }
  }

  private getLogo(account: AccountAccess | TrialPlan): string {
    const accountAccess = account as AccountAccess;
    return Utils.getAccountLogo(accountAccess.accountLogoDownloadLink, StorageUtils.getTokenForId(accountAccess.accountId, accountAccess.accountAccessToken));
  }

  private createViewModel(accounts: (AccountAccess | TrialPlan)[], selectedAccount: AccountAccess | TrialPlan): AccountSelectorViewModel {
    return {
      managedAccountsAndTrialPlans: accounts.map(account => ({ ...account, finalName: this.getName(account) })),
      id: TypeUtil.getAccountAccessOrTrialPlanId(selectedAccount),
      logo: this.getLogo(selectedAccount),
      name: this.getName(selectedAccount)
    };
  }
}
