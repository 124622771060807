import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';

@Component({
  selector: 'sacc-icon-text-button',
  templateUrl: './icon-text-button.component.html',
  styleUrls: ['./icon-text-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CelumIconModule, MatButtonModule],
  standalone: true
})
export class IconTextButtonComponent implements OnChanges {
  @Input() public label: string;
  @Input() public icon: string;
  @Input() public disabled: boolean;
  @Input() public size: 'big' | 'medium' = 'big';
  @Input() public width: 'normal' | 'wide' = 'normal';
  @Input() public color: 'primary' | 'secondary' | 'none' = 'primary';
  @Output() public readonly clicked = new EventEmitter<Event>();

  protected iconConfiguration: IconConfiguration;
  public ngOnChanges({ icon }: SimpleChanges): void {
    if (icon?.previousValue !== this.icon) {
      this.iconConfiguration = new IconConfiguration(this.icon).withIconSize(16).withColor('inherit');
    }
  }
}
