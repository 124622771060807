import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NEVER, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '@celum/authentication';
import { CelumPropertiesProvider } from '@celum/core';
import { BatchDTO, BatchParams, getPaginationParams, Repository, SingleAssociatedAccountResponseDto } from '@celum/sacc/domain';

import { RestService } from './rest.service';
import { Constants } from '../constants';
import { AppState } from '../store/app.state';
import { notificationActions } from '../store/notification/notification.action';
import { UserSignInFederationDomain } from '../store/user/user.effects';
import { Utils } from '../utils';

@Injectable({ providedIn: 'root' })
export class RepositoryResourceService extends RestService {
  private accountRecordHeight = 48;
  private readonly batchSize = Utils.calculateBatchSize(this.accountRecordHeight);

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService,
    private store$: Store<AppState>
  ) {
    super();
  }

  public registerRepository(repository: Repository): Observable<Repository> {
    return this.httpClient.post<Repository>(this.getApiUrl(`/repositories`), repository);
  }

  public updateRepository(repository: Repository): Observable<Repository> {
    return this.httpClient.put<Repository>(this.getApiUrl(`/repositories/${repository.repositoryId}`), repository);
  }

  public deleteRepository(repositoryId: string): Observable<string> {
    return this.httpClient.delete(this.getApiUrl(`/repositories/${repositoryId}`)).pipe(map(() => repositoryId));
  }

  public getRepository(repositoryId: string): Observable<Repository> {
    return this.httpClient.get<Repository>(this.getApiUrl(`/repositories/${repositoryId}`));
  }

  public fetchBatch(batchParams: BatchParams = {}, batchSize?: number): Observable<BatchDTO<Repository>> {
    return this.httpClient.get<BatchDTO<Repository>>(this.getApiUrl(`/repositories`), {
      params: getPaginationParams({
        ...batchParams,
        count: batchSize ?? this.batchSize
      })
    });
  }

  public search(filter: string, batchParams: BatchParams = {}): Observable<BatchDTO<Repository>> {
    return this.httpClient.post<BatchDTO<Repository>>(
      this.getApiUrl(`/repositories/search`),
      { filter },
      {
        params: getPaginationParams({
          ...batchParams,
          count: this.batchSize
        })
      }
    );
  }

  public isSingleAccountAssociatedWithRepository(repositoryId: string): Observable<SingleAssociatedAccountResponseDto> {
    return this.httpClient.get<SingleAssociatedAccountResponseDto>(this.getApiUrl(`/repositories/${repositoryId}/single-account`));
  }

  // check if the url is valid and if so, then redirect
  public checkUrlAndRedirect(url: string, userFederation: UserSignInFederationDomain, accountId?: string): Observable<void> {
    sessionStorage.removeItem(Constants.REDIRECT_URL);
    let redirectUrl: URL;
    try {
      redirectUrl = new URL(url);
    } catch (error) {
      return of(null);
    }
    if (localStorage.getItem(Constants.NEW_TRIAL_QUERY_PARAM)) {
      return of(null);
    }

    // Otherwise check if the redirect url is a known repository
    return this.isRepositoryContainingRedirectUrl(url).pipe(
      switchMap(isValid => {
        if (isValid) {
          return this.redirect(redirectUrl, userFederation, accountId);
        }
        this.store$.dispatch(notificationActions.error({ message: this.translateService.instant('GUARDS.REDIRECT_PREVENTED') }));
        return of(null);
      })
    );
  }

  public importFromCH(repositoryId: string, organisationId: string): Observable<void> {
    return this.httpClient.put<void>(this.getApiUrl(`/repositories/${repositoryId}/users/migrate`), {
      accountId: organisationId
    });
  }

  private redirect(redirectUrl: URL, userFederation: UserSignInFederationDomain, accountId: string): Observable<void> {
    const props = CelumPropertiesProvider.properties.authentication;
    const tenantName = props.aDDomain;
    const authority = `${props.b2cDomain}/tfp/${tenantName}.onmicrosoft.com/${props.defaultSigninPolicy}`;

    return this.authService.getAuthResult().pipe(
      map(r => r?.accessToken || ''),
      switchMap(token => {
        if (redirectUrl.href.includes('/connectcloudaccount')) {
          redirectUrl.searchParams.append('id_token', token);
          redirectUrl.searchParams.append('authority', authority);
        }
        if (accountId) {
          redirectUrl.searchParams.append('organization_id', accountId);
        }
        if (redirectUrl.toString().startsWith('http://localhost')) {
          redirectUrl.searchParams.append('userFederation', JSON.stringify(userFederation));
        }
        window.history.replaceState({}, 'Account Management', '/home');
        window.location.replace(redirectUrl.toString());
        return NEVER;
      })
    );
  }

  private isRepositoryContainingRedirectUrl(redirectUrl: string): Observable<boolean> {
    if (!redirectUrl) {
      return of(false);
    }
    const feUrl = (window as any).Celum.properties.frontendUrl;
    if (redirectUrl.startsWith(feUrl)) {
      return of(true);
    }
    return this.httpClient.get<boolean>(this.getApiUrl(`/repositories/authorized-redirect-url`), {
      params: { url: redirectUrl }
    });
  }
}
