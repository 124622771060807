import { createReducer, on } from '@ngrx/store';

import { federationActions } from './federation.action';
import { federationAdapter, initialFederationState } from './federation.state';

export const federationReducer = createReducer(
  initialFederationState,
  on(federationActions.fetchBatch, state => ({ ...state, loading: true })),
  on(federationActions.fetchBatchFailure, state => ({ ...state, loading: false })),
  on(federationActions.fetchBatchSuccess, (state, { batch }) => ({
    ...federationAdapter.addMany(batch.entities, state),
    continuationToken: batch.continuationToken,
    totalCount: batch.totalCount,
    filterCount: batch.filterCount,
    loading: false
  })),
  on(federationActions.search, (state, action) => ({
    ...state,
    loading: true,
    continuationToken: state.continuationToken,
    filter: action.filter
  })),
  on(federationActions.filterChanged, (state, action) => ({
    ...federationAdapter.removeAll(state),
    loading: true,
    continuationToken: '',
    filter: action.filter
  })),
  on(federationActions.searchFailure, state => ({ ...state, loading: false })),
  on(federationActions.searchSuccess, (state, { batch }) => {
    return {
      ...federationAdapter.addMany(batch.entities, state),
      continuationToken: batch.continuationToken,
      totalCount: batch.totalCount,
      filterCount: batch.filterCount,
      loading: false
    };
  }),
  on(federationActions.resetFederationTable, state => ({ ...initialFederationState, filter: state.filter })),
  on(federationActions.resetFederationTableFilter, state => ({ ...state, filter: '' })),
  on(federationActions.registerSuccess, (state, action) => ({
    ...federationAdapter.upsertOne(action.federation, {
      ...state,
      totalCount: state.totalCount + 1,
      filterCount: state.filterCount + 1
    })
  })),
  on(federationActions.updateSuccess, (state, action) => ({ ...federationAdapter.upsertOne(action.federation, state) })),
  on(federationActions.deleteSuccess, (state, { federationId }) => federationAdapter.removeOne(federationId, { ...state, totalCount: state.totalCount - 1 }))
);
