import { ErrorHandler, Injectable } from '@angular/core';

import { ApplicationInsightsService } from '@celum/sacc/shared';

import { environment } from '../../environments/environment';

/**
 * Application-wide error handler that adds an app-insights logging to the error handling provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private appInsights: ApplicationInsightsService) {
    super();
  }

  public handleError(error: Error): void {
    this.appInsights.logError(error);
    if (!environment.production) {
      console.error(error);
    }
  }
}
