import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';

import { CelumInputsModule } from '@celum/common-components';

import { CustomDateAdapter } from './custom-date-adapter';
import { DatePickerWrapper } from './datepicker-wrapper';

@NgModule({
            imports: [
              CommonModule, ReactiveFormsModule,
              MatNativeDateModule, MatDatepickerModule, MatFormFieldModule, CelumInputsModule
            ],
            declarations: [
              DatePickerWrapper
            ],
            providers: [
              {
                provide: DateAdapter,
                useClass: CustomDateAdapter
              }
            ],
            exports: [
              DatePickerWrapper, CelumInputsModule, MatDatepickerModule, MatFormFieldModule
            ]
          })
export class CelumDatepickerWrapperModule {
}
