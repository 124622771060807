import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { AccountAccess } from '@celum/authentication';
import { CelumAvatarModule, CelumEmptyPageModule, CelumIconModule, CelumLoadingMaskModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumDirectivesModule, CelumPipesModule, TranslationModule } from '@celum/ng2base';
import { AccountMember, UserGroup } from '@celum/sacc/domain';
import { SearchAndSelectComponent } from '@celum/shared/ui';
import { UserAvatarComponent } from '@celum/shared/ui-people';

import { UserFilterService } from './user-filter.service';

@Component({
  selector: 'sacc-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  // This is needed because the style of the search and select must be changed
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    MatChipsModule,
    MatTooltipModule,
    OverlayModule,
    TranslateModule,
    TranslationModule,

    CelumAvatarModule,
    CelumDirectivesModule,
    CelumEmptyPageModule,
    CelumIconModule,
    CelumLoadingMaskModule,
    CelumPipesModule,

    SearchAndSelectComponent,
    UserAvatarComponent
  ],
  providers: [UserFilterService]
})
export class UserFilterComponent {
  @Output() public readonly userAdded = new EventEmitter<AccountMember>();
  @Output() public readonly userRemoved = new EventEmitter<AccountMember>();

  protected readonly addNewUser = IconConfiguration.large('add-user')
    .withIconSize(32)
    .withColor(ColorConstants.BLUE_GRAY_600)
    .withHoverColor(ColorConstants.BLUE_GRAY_700);

  constructor(protected service: UserFilterService) {}

  @Input()
  public set addedUsers(addedUsers: AccountMember[]) {
    this.service.patchState({ addedUsers });
  }

  @Input()
  public set removedUsers(removedUsers: AccountMember[]) {
    this.service.patchState({ removedUsers });
  }

  @Input()
  public set accountAccess(accountAccess: AccountAccess) {
    this.service.patchState({ accountAccess });
  }

  @Input()
  public set userLimit(userLimit: number) {
    this.service.patchState({ userLimit });
  }

  @Input()
  public set userGroup(userGroup: UserGroup) {
    this.service.patchState({ userGroup });
  }

  protected readonly compareFn = (a: AccountMember, b: AccountMember) => a.email === b.email;

  protected selectionChanged(current: AccountMember[], previous: AccountMember[]): void {
    if (current.length > previous.length) {
      const newItem = current.find(item => !previous.some(previousItem => previousItem.email === item.email));
      this.userAdded.emit(newItem);
      return;
    }

    const removedItem = previous.find(item => !current.some(previousItem => previousItem.email === item.email));
    this.userRemoved.emit(removedItem);
  }
}
