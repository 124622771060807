import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class SaccBlobService extends RestService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  /**
   * Uploads a user avatar for the current user
   * @param file - a jpeg picture smaller than X bytes
   */
  public uploadAvatar(file: File): Observable<string> {
    const url = this.getApiUrl('/users/current/avatar');
    return this.httpClient.put<{ downloadLink: string }>(url, file).pipe(map(response => response.downloadLink));
  }

  /**
   * Uploads a logo for the specified account
   * @param accountId - the ID of the account the logo should be uploaded for
   * @param file - a jpeg picture smaller than X bytes
   */
  public uploadLogo(accountId: string, file: File): Observable<string> {
    const url = this.getApiUrl(`/accounts/${accountId}/logo`);
    return this.httpClient.put<{ downloadLink: string }>(url, file).pipe(map(response => response.downloadLink));
  }
}
