<celum-dialog (onCloseAll)="onCancel()">
  <celum-dialog-content>
    <div class="sacc-confirmation-dialog">
      <img class="sacc-confirmation-dialog_image" [src]="type === 'warning' ? 'assets/images/dialogs/warning.svg' : 'assets/images/dialogs/info.svg'" alt="" />
      <h2 class="sacc-confirmation-dialog_headline light" [class.sacc-confirmation-dialog_headline--warning]="type === 'warning'">{{ title | translate }}</h2>

      <div class="sacc-confirmation-dialog_text">
        <p>{{ caption | translate }}</p>
        <p class="semi-bold">{{ description | translate }}</p>
      </div>
    </div>
  </celum-dialog-content>
  <celum-dialog-footer
    [buttons]="['cancel', !hideConfirmButton ? 'ok' : null]"
    [color]="type === 'warning' ? ColorConstants.WARNING : ColorConstants.PRIMARY"
    [confirmBtnText]="confirmationButtonText || 'COMPONENTS.DIALOG.CONFIRMATION_DIALOG.YES' | translate | uppercase"
    [cancelBtnText]="cancelButtonText || 'COMPONENTS.DIALOG.CONFIRMATION_DIALOG.NO' | translate | uppercase"
    (onCancel)="onCancel()"
    (onConfirm)="onConfirm()"></celum-dialog-footer>
</celum-dialog>
