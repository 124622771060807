import { Sort } from '@angular/material/sort';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Repository } from '@celum/sacc/domain';

export interface RepositoryState extends EntityState<Repository> {
  loading: boolean;
  sort: Sort;
  continuationToken: string;
  totalCount: number;
  filterCount: number;
  filter: string;
}

export const repositoryAdapter: EntityAdapter<Repository> = createEntityAdapter<Repository>({
  selectId: repository => repository.repositoryId
});

export const initialRepositoryState: RepositoryState = repositoryAdapter.getInitialState({
  sort: null,
  continuationToken: '',
  loading: false,
  totalCount: 0,
  filterCount: 0,
  filter: ''
});
