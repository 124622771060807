import { Directive, HostBinding, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef, ViewRef } from '@angular/core';

/**
 * Allows adding extra information to a table row that can be expanded or collapsed.
 */
@Directive({
  selector: '[saccExpandableItemRow]',
  standalone: true
})
export class RowDirective implements OnChanges {
  /**
   * Allows triggering the expansion/collapse of the row
   */
  @Input()
  public opened: boolean;

  /**
   * The rows data
   */
  public row: ViewRef;

  /**
   * Template to display in the expanded row
   */
  protected templateReference: TemplateRef<any>;

  constructor(public viewContainerReference: ViewContainerRef) {}

  /**
   * Set the data-item for the row
   */
  @Input()
  set rowItem(row: ViewRef) {
    if (row !== this.row) {
      this.row = row;
    }
  }

  /**
   * Template to render in the expanded row
   */
  @Input()
  set itemDetailsTemplate(value: TemplateRef<any>) {
    if (value !== this.templateReference) {
      this.templateReference = value;
    }
  }

  @HostBinding('class.expanded')
  get expanded(): boolean {
    return this.opened;
  }

  public ngOnChanges({ opened }: SimpleChanges): void {
    if (opened !== undefined) {
      if (opened.currentValue) {
        this.render();
      } else {
        this.viewContainerReference.clear();
      }
    }
  }

  private render(): void {
    this.viewContainerReference.clear();
    if (this.templateReference && this.row) {
      this.viewContainerReference.createEmbeddedView(this.templateReference, { $implicit: this.row });
    }
  }
}
