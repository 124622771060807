import { createSelector } from '@ngrx/store';

import { accountMemberAdapter } from './account-member.state';
import { AppState } from '../app.state';

export const selectAccountMemberState = (state: AppState) => state.accountMembers;

export const selectAccountMembersAll = createSelector(selectAccountMemberState, accountMemberAdapter.getSelectors().selectAll);

export const selectAccountMemberCount = createSelector(selectAccountMemberState, accountMemberState => accountMemberState.totalCount);

export const selectAccountMemberFilterCount = createSelector(selectAccountMemberState, accountMemberState => accountMemberState.filterCount);

export const selectAccountMembersLoading = createSelector(selectAccountMemberState, accountMemberState => accountMemberState.loading);

export const selectAccountMembersAllLoaded = createSelector(
  selectAccountMemberState,
  selectAccountMembersAll,
  (accountMemberState, allAccountMembers) => allAccountMembers.length > 0 && !accountMemberState.continuationToken
);

export const selectAccountMemberFilter = createSelector(selectAccountMemberState, accountMemberState => accountMemberState.filter);

export const selectAccountMemberNextBatchParams = createSelector(selectAccountMemberState, accountMemberState => {
  return {
    count: accountMemberAdapter.getSelectors().selectAll(accountMemberState).length,
    sort: accountMemberState.sort,
    continuationToken: accountMemberState.continuationToken
  };
});
