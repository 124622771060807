import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { ClipboardModule } from 'ngx-clipboard';

import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { AppState, notificationActions } from '@celum/sacc/shared';

import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
  selector: 'sacc-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  imports: [ClipboardModule, MatTooltipModule, CelumIconModule, IconButtonComponent],
  standalone: true
})
export class CopyToClipboardComponent {
  @Input() public tooltip: string;
  @Input() public copyValue: string;
  @Input() public successMessage: string;
  @Input() public failureMessage: string;
  @Input() public showCopyIcon = false;
  @Input() public alternateCopyIcon: IconConfiguration;

  protected icons: { [key: string]: IconConfiguration } = {
    id: new IconConfiguration('ID').withIconSize(20).withColor(ColorConstants.STANDARD_BLACK),
    copy: new IconConfiguration('duplicate-s').withIconSize(20)
  };

  constructor(private store$: Store<AppState>) {}

  public onCopy(event: any): void {
    if (event.isSuccess && this.successMessage) {
      this.store$.dispatch(notificationActions.info({ message: this.successMessage }));
    } else if (!event.isSuccess && this.failureMessage) {
      this.store$.dispatch(notificationActions.error({ message: this.failureMessage }));
    }
  }
}
