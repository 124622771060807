import { Sort } from '@angular/material/sort';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Account } from '@celum/sacc/domain';

export interface AccountState extends EntityState<Account> {
  sort: Sort;
  continuationToken: string;
  loading: boolean;
  selectedAccountId: string;
  totalCount: number;
  filterCount: number;
  filter: string;
}

export const accountAdapter: EntityAdapter<Account> = createEntityAdapter<Account>();

export const initialAccountState: AccountState = accountAdapter.getInitialState({
  loading: false,
  sort: null,
  continuationToken: '',
  selectedAccountId: '',
  totalCount: 0,
  filterCount: 0,
  filter: ''
});
