import { Sort } from '@angular/material/sort';

const defaultPageSize = 50;

export interface BatchParams {
  count?: number;
  sort?: Sort;
  continuationToken?: string;
}

export interface BatchDTO<T> {
  entities: T[];
  continuationToken: string;
  totalCount: number;
  filterCount: number;
}

export function getPaginationParams({ count, sort, continuationToken }: BatchParams): any {
  let params: any = {
    size: (count ?? defaultPageSize).toString()
  };

  if (continuationToken) {
    params = {
      ...params,
      continuationToken
    };
  }

  if (sort) {
    const { active, direction } = sort;
    params = {
      ...params,
      sort: `${active},${direction}`
    };
  }

  return params;
}
