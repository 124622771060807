import { Injectable } from '@angular/core';

import { ContentHubLicense, ExperienceLicense, License, LicenseType, WorkroomsLicense } from '@celum/authentication';

type ServiceDetails = {
  name: string;
  activeServiceSmallLogoUri: string;
  inactiveServiceSmallLogoUri: string;
  notAvailableServiceSmallLogoUri: string;
  activeServiceLogoUri: string;
  inactiveServiceLogoUri: string;
  notAvailableServiceLogoUri: string;
};

@Injectable({ providedIn: 'root' })
export class LicenseDetailsService {
  public serviceDetails: Record<LicenseType, ServiceDetails | undefined> = {
    [LicenseType.CONTENT_HUB]: {
      name: 'CELUM Content',
      activeServiceSmallLogoUri: '../../../assets/images/licenses/content-hub/content-hub-small.svg',
      inactiveServiceSmallLogoUri: '../../../assets/images/licenses/content-hub/content-hub-small-inactive.svg',
      notAvailableServiceSmallLogoUri: '../../../assets/images/licenses/content-hub/content-hub-small-not-available.svg',
      activeServiceLogoUri: '../../../assets/images/licenses/content-hub/logo-content-hub.svg',
      inactiveServiceLogoUri: '../../../assets/images/licenses/content-hub/logo-content-hub-inactive.svg',
      notAvailableServiceLogoUri: '../../../assets/images/licenses/content-hub/logo-content-hub-not-available.svg'
    },
    [LicenseType.WORK]: {
      name: 'CELUM Work',
      activeServiceSmallLogoUri: '../../../assets/images/licenses/workrooms/logo-workrooms-small.svg',
      inactiveServiceSmallLogoUri: '../../../assets/images/licenses/workrooms/logo-workrooms-inactive-small.svg',
      notAvailableServiceSmallLogoUri: '../../../assets/images/licenses/workrooms/logo-workrooms-not-available-small.svg',
      activeServiceLogoUri: '../../../assets/images/licenses/workrooms/logo-workrooms.svg',
      inactiveServiceLogoUri: '../../../assets/images/licenses/workrooms/logo-workrooms-inactive.svg',
      notAvailableServiceLogoUri: '../../../assets/images/licenses/workrooms/logo-workrooms-not-available.svg'
    },
    [LicenseType.EXPERIENCE]: {
      name: 'CELUM Experience',
      activeServiceSmallLogoUri: '../../../assets/images/licenses/experience/experience-small.svg',
      inactiveServiceSmallLogoUri: '../../../assets/images/licenses/experience/experience-small-inactive.svg',
      notAvailableServiceSmallLogoUri: '../../../assets/images/licenses/experience/experience-small-not-available.svg',
      activeServiceLogoUri: '../../../assets/images/licenses/experience/logo-experience.svg',
      inactiveServiceLogoUri: '../../../assets/images/licenses/experience/logo-experience-inactive.svg',
      notAvailableServiceLogoUri: '../../../assets/images/licenses/experience/logo-experience-not-available.svg'
    },
    [LicenseType.DRIVE]: undefined,
    [LicenseType.X3D]: undefined,
    [LicenseType.XSIM_SEARCH]: undefined,
    [LicenseType.NO_LICENSE]: undefined
  };

  public getLinkUrl(license: License): string {
    if (!license) {
      return '';
    }

    switch (license.type) {
      case LicenseType.EXPERIENCE:
        return (license as ExperienceLicense).experienceTenantAwareUrl;
      case LicenseType.CONTENT_HUB:
        if ((license as ContentHubLicense).chRepositories === null || (license as ContentHubLicense).chRepositories.length === 0) {
          return '';
        }
        return (license as ContentHubLicense).chRepositories[0].url;
      case LicenseType.WORK:
        return (license as WorkroomsLicense).wrTenantAwareUrl;
      default:
        return '';
    }
  }
}
