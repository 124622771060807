import { createReducer, on } from '@ngrx/store';

import { accountMemberActions } from './account-member.action';
import { accountMemberAdapter, initialAccountMemberState } from './account-member.state';

export const accountMemberReducer = createReducer(
  initialAccountMemberState,
  on(accountMemberActions.fetchBatch, state => ({ ...state, loading: true })),
  on(accountMemberActions.fetchBatchFailure, state => ({ ...state, loading: false })),
  on(accountMemberActions.fetchBatchSuccess, (state, { batch }) => ({
    ...accountMemberAdapter.upsertMany(batch.entities, state),
    continuationToken: batch.continuationToken,
    totalCount: batch.totalCount,
    filterCount: batch.filterCount,
    loading: false
  })),
  on(accountMemberActions.search, state => ({
    ...state,
    loading: true,
    continuationToken: state.continuationToken
  })),
  on(accountMemberActions.filterChanged, (state, action) => ({
    ...accountMemberAdapter.removeAll(state),
    loading: true,
    continuationToken: '',
    filter: action.filter
  })),
  on(accountMemberActions.searchFailure, state => ({ ...state, loading: false })),
  on(accountMemberActions.searchSuccess, (state, { batch }) => {
    return {
      ...accountMemberAdapter.upsertMany(batch.entities, state),
      continuationToken: batch.continuationToken,
      totalCount: batch.totalCount,
      filterCount: batch.filterCount,
      loading: false
    };
  }),
  on(accountMemberActions.sortChanged, (_, { sort }) => ({ ...initialAccountMemberState, sort })),
  on(accountMemberActions.resetAccountTable, state => ({ ...initialAccountMemberState, filter: state.filter })),
  on(accountMemberActions.resetAccountTableFilter, state => ({ ...state, filter: '' })),
  on(accountMemberActions.updateSuccess, (state, { accountMember }) => ({
    ...accountMemberAdapter.upsertOne(accountMember, state)
  })),
  on(accountMemberActions.removeSuccess, (state, { accountMemberId }) => ({
    ...accountMemberAdapter.removeOne(accountMemberId, {
      ...state,
      totalCount: state.totalCount - 1,
      filterCount: state.filterCount - 1
    })
  }))
);
