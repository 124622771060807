import { createSelector } from '@ngrx/store';

import { AccountAccess, AccountAccessStatus, AccountUserRole, ContentHubLicense, InvitationStatus, isContentHubLicense, License } from '@celum/authentication';

import { TypeUtil } from '../../type.util';
import { selectAccountActiveAccountId } from '../account/account.selectors';
import { AppState } from '../app.state';

export const selectUserState = (state: AppState) => state.users;

export const selectUserCurrent = createSelector(selectUserState, userState => userState.currentUser);

export const selectUserCurrentEmail = createSelector(selectUserCurrent, currentUser => currentUser && currentUser.email);

export const selectUserCurrentLocale = createSelector(selectUserCurrent, currentUser => currentUser && currentUser.locale);

export const selectUserAllAccountAccesses = createSelector(
  selectUserCurrent,
  currentUser => (currentUser && currentUser.accountAccesses) || ([] as AccountAccess[])
);

export const selectUserAllUserInvitations = (invitationStatus: InvitationStatus) =>
  createSelector(
    selectUserCurrent,
    currentUser => (currentUser && currentUser.invitations.filter(invitation => invitationStatus === invitation.invitationStatus)) || []
  );

export const selectUserIsAccountManager = createSelector(selectUserAllAccountAccesses, accountAccesses => accountAccesses.some(isActiveManager));

export const selectUserActiveAccountAccesses = createSelector(selectUserAllAccountAccesses, accountAccessList =>
  accountAccessList.filter(accountAccess => accountAccess.status === AccountAccessStatus.ACTIVE)
);

export const selectUserAllManagedAccountAccesses = createSelector(selectUserAllAccountAccesses, accountAccessList => accountAccessList.filter(isActiveManager));

export const selectUserIsAccountOwner = createSelector(selectUserCurrentEmail, selectUserAllAccountAccesses, (email, accountAccesses) =>
  accountAccesses.some(accAccess => accountOwner(email, accAccess))
);

export const selectUserHasCurrentUserAccountAccess = (accountId: string) =>
  createSelector(selectUserAllAccountAccesses, accountAccesses => accountAccesses.some(accAccess => accAccess.accountId === accountId));

export const selectUserHasCurrentUserRepositoryAssociatedAccountAccess = (accountId: string, repositoryId: string) =>
  createSelector(selectUserAllAccountAccesses, accountAccesses => {
    const accountAccess: AccountAccess = accountAccesses.find((accAccess: AccountAccess) => accAccess.accountId === accountId);

    if (!accountAccess || !accountAccess.licenses || !repositoryId) {
      return false;
    }

    return accountAccess.licenses
      .filter(isContentHubLicense)
      .some((chLicense: ContentHubLicense) => chLicense.chRepositories.some(chRepository => chRepository.repositoryId === repositoryId));
  });

export const selectUserAccountAccessByActiveAccountId = createSelector(
  selectUserAllAccountAccesses,
  selectAccountActiveAccountId,
  (accountAccesses, activeAccId) => accountAccesses.find(accAccess => accAccess.accountId === activeAccId)
);

export const selectUserIsAccountManagerOfActiveAccount = createSelector(selectUserAccountAccessByActiveAccountId, accountAccess =>
  isActiveManager(accountAccess)
);

export const selectUserIsAccountOwnerOfActiveAccount = createSelector(selectUserCurrent, selectUserAccountAccessByActiveAccountId, (user, access) =>
  accountOwner(user.email, access)
);

export const selectUserAllLicensesByActiveAccountId = createSelector(selectUserAccountAccessByActiveAccountId, accountAccess =>
  accountAccess ? accountAccess.licenses : []
);

export const selectUserLicenseByActiveAccountIdAndLicenseType = (licenseType: string) =>
  createSelector(selectUserAllLicensesByActiveAccountId, (licenses: License[]) => licenses.find(accountAccess => accountAccess.type === licenseType));

export const selectUserCanEditActiveAccount = createSelector(selectUserAccountAccessByActiveAccountId, access => isActiveManager(access));

export const selectUserCanInviteToActiveAccount = createSelector(selectUserAccountAccessByActiveAccountId, access => isActiveManager(access));

export const selectUserManagedAccountsAndTrialPlan = createSelector(selectUserAllManagedAccountAccesses, selectUserCurrent, (accountAccesses, user) =>
  [...accountAccesses, user.trialPlan].filter(account => account && TypeUtil.getAccountAccessOrTrialPlanId(account))
);

export const selectUserSelectedManagedAccountOrTrialPlanByActiveId = createSelector(
  selectUserManagedAccountsAndTrialPlan,
  selectUserCurrent,
  selectAccountActiveAccountId,
  (accountAccesses, user, activeId) =>
    [...accountAccesses, user.trialPlan].find(account => account && TypeUtil.getAccountAccessOrTrialPlanId(account) === activeId)
);

export const selectUserSelectedAccountOrTrialPlanByActiveId = createSelector(
  selectUserActiveAccountAccesses,
  selectAccountActiveAccountId,
  (accountAccesses, activeId) => accountAccesses.find(account => account && account.accountId === activeId)
);

function isActiveManager(accAccess: AccountAccess): boolean {
  return accAccess && accAccess.status === AccountAccessStatus.ACTIVE && accAccess.role === AccountUserRole.MANAGER;
}

function accountOwner(email: string, accAccess: AccountAccess): boolean {
  return accAccess && accAccess.ownerEmail === email;
}
