import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { AvatarConfiguration, CelumAvatarModule } from '@celum/common-components';

@Component({
  selector: 'sacc-group-avatar',
  templateUrl: './group-avatar.component.html',
  styleUrl: './group-avatar.component.scss',
  standalone: true,
  imports: [CelumAvatarModule],
  // Needed to be able to apply styling to the avatar
  encapsulation: ViewEncapsulation.None
})
export class GroupAvatarComponent {
  @HostBinding('class.sacc-group-avatar') public hostCls = true;
  @HostBinding('attr.data-test-group-avatar') public hostTestAttr = true;
  @Input() public config: AvatarConfiguration;
}
