import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';
import { TranslationHelper } from '@celum/ng2base';
import { ClickablePopupDialogComponent } from '@celum/shared/ui';

@Component({
  selector: 'sacc-privilege-info-popup-dialog',
  templateUrl: './privilege-info-popup-dialog.component.html',
  styleUrl: './privilege-info-popup-dialog.component.scss',
  standalone: true,
  // Needed to style the content of the menu/popup
  encapsulation: ViewEncapsulation.None,
  imports: [TranslateModule, ClickablePopupDialogComponent, CelumIconModule]
})
export class PrivilegeInfoPopupDialogComponent {
  protected urlTarget: string;
  protected readonly iconQuestion = IconConfiguration.large('question-icon');
  constructor(translateService: TranslationHelper) {
    this.urlTarget = `https://help.celum.com/${translateService.locale === 'de' ? 'de' : 'en'}/topic.htm#t=mergedProjects%2FcloudAccount%2FtocTopics%2FManage_celum_organization_member_roles.htm`;
  }
}
