import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { UserDetails } from '@celum/authentication';

export interface UserState extends EntityState<UserDetails> {
  currentUser: UserDetails;
  authError: string;
}

export const userAdapter: EntityAdapter<UserDetails> = createEntityAdapter<UserDetails>({
  selectId: user => user.oid
});

export const initialUserState: UserState = userAdapter.getInitialState({
  currentUser: null,
  authError: null
});
