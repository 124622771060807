import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../store/app.state';
import { loaderActions } from '../store/loader/loader.action';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor(private store$: Store<AppState>) {}
  public show(): void {
    this.store$.dispatch(loaderActions.show());
  }

  public hide(): void {
    this.store$.dispatch(loaderActions.hide());
  }
}
