@if (service.vm$ | async; as vm) {
  <div class="sacc-account-selector" data-test-sacc-account-selector>
    <img alt="Account selector" class="account-logo" data-test-sacc-account-selector-logo [src]="vm.logo" />
    <span class="sacc-form-field">
      @if (vm.managedAccountsAndTrialPlans.length > 1) {
        <mat-select
          class="sacc-account-selector_selected-account"
          data-test-sacc-account-selector-dropdown
          [panelClass]="'sacc-account-selector_panel'"
          [matTooltip]="vm.name"
          [matTooltipDisabled]="vm.name.length <= 40"
          [placeholder]="vm.name"
          [value]="vm.id"
          (selectionChange)="selectedAccountChanged($event.value)">
          @for (account of vm.managedAccountsAndTrialPlans; track accountTracker(index, account); let index = $index) {
            @if (account.finalName; as accountName) {
              <mat-option
                class="sacc-account-selector_selected-account-item"
                [attr.data-test-sacc-account-selector-dropdown-item]="index"
                [matTooltip]="accountName"
                [matTooltipDisabled]="accountName.length <= 71"
                [value]="TypeUtil.getAccountAccessOrTrialPlanId(account)">
                {{ accountName }}
              </mat-option>
            }
          }
        </mat-select>
      }
    </span>

    @if (vm.managedAccountsAndTrialPlans.length === 1) {
      <span class="sacc-account-selector_selected-account" [matTooltip]="vm.name" data-test-sacc-account-selector-label spaceAwareTooltip>
        {{ vm.name }}
      </span>
    }

    <sacc-copy-to-clipboard
      class="sacc-account-selector_copy-id"
      failureMessage="{{ 'COMPONENTS.ACCOUNT_PAGE.ID_COPY_FAILURE' | translate }}"
      successMessage="{{ 'COMPONENTS.ACCOUNT_TABLE.ID_COPY_SUCCESS' | translate }}"
      tooltip="{{ 'COMPONENTS.DIALOG.INVITE_DIALOG.SHARE_THIS_LINK_COPY' | translate }}"
      [copyValue]="vm.id"></sacc-copy-to-clipboard>
  </div>
}
