import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CelumPropertiesProvider } from '@celum/core';
import {
  AccountMember,
  AutoProvisioningTokenConfiguration,
  BatchDTO,
  BatchParams,
  Federation,
  FederationGroup,
  FederationUser,
  getPaginationParams,
  ProvisioningToken
} from '@celum/sacc/domain';
import { PaginationOption } from '@celum/shared/domain';
import { Result } from '@celum/shared/util';

import { RestService } from './rest.service';
import { UserSignInFederationDomain } from '../store/user/user.effects';
import { Utils } from '../utils';

export interface FederationUserFindOptions extends PaginationOption {
  ids?: string[];
  filter?: string;
  sort?: FederationUserSorter;
  continuationToken?: string;
}

export interface FederationGroupFindOptions extends PaginationOption {
  ids?: string[];
  filter?: string;
  sort?: FederationGroupSorter;
  continuationToken?: string;
}

export type FederationUserSorter = {
  key: keyof Pick<AccountMember, 'firstName' | 'lastName' | 'email'>;
  order: 'desc' | 'asc';
};

export type FederationGroupSorter = {
  key: keyof Pick<FederationGroup, 'displayName'>;
  order: 'desc' | 'asc';
};

@Injectable({ providedIn: 'root' })
export class FederationResourceService extends RestService {
  private static readonly USER_FEDERATION_COOKIE = 'user.federation';

  private federationRecordHeight = 48;
  private readonly batchSize = Utils.calculateBatchSize(this.federationRecordHeight);

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {
    super();
  }

  /** @deprecated remove when WR2 doesn't need this cookie anymore */
  public getSignInFederation(email: string): UserSignInFederationDomain {
    const cookieFederation = this.cookieService.get(FederationResourceService.USER_FEDERATION_COOKIE);
    const federation = cookieFederation && JSON.parse(cookieFederation);
    if (
      email &&
      federation?.email?.toLowerCase() === email.toLowerCase() &&
      federation?.tenantName?.toLowerCase() === CelumPropertiesProvider.properties.authentication.aDDomain
    ) {
      return federation;
    }
    this.clearUserFederation();
    return undefined;
  }

  /** @deprecated remove when WR2 doesn't need this cookie anymore */
  public clearUserFederation(): void {
    this.cookieService.delete(FederationResourceService.USER_FEDERATION_COOKIE, '/', (window as any).Celum.properties.cookieDomain);
  }

  /** @deprecated remove when WR2 doesn't need this cookie anymore */
  public storeUserFederationInCookie(userFederation: UserSignInFederationDomain): void {
    this.cookieService.set(
      FederationResourceService.USER_FEDERATION_COOKIE,
      JSON.stringify({
        ...userFederation,
        tenantName: CelumPropertiesProvider.properties.authentication.aDDomain
      }),
      365,
      '/',
      (window as any).Celum.properties.cookieDomain
    );
  }

  public registerFederation(federation: Federation): Observable<Federation> {
    return this.httpClient.post<Federation>(this.getApiUrl(`/federations`), federation);
  }

  public fetchBatch(batchParams: BatchParams = {}, batchSize?: number): Observable<BatchDTO<Federation>> {
    return this.httpClient.get<BatchDTO<Federation>>(this.getApiUrl(`/federations`), {
      params: getPaginationParams({
        ...batchParams,
        count: batchSize ?? this.batchSize
      })
    });
  }

  public getById(federationId: string): Observable<Federation> {
    return this.httpClient.get<Federation>(this.getApiUrl(`/federations/${federationId}`));
  }

  public search(filter: string, batchParams: BatchParams = {}): Observable<BatchDTO<Federation>> {
    return this.httpClient.post<BatchDTO<Federation>>(
      this.getApiUrl(`/federations/search`),
      { filter },
      {
        params: getPaginationParams({
          ...batchParams,
          count: this.batchSize
        })
      }
    );
  }

  public update(federation: Partial<Federation>): Observable<Federation> {
    return this.httpClient.patch<Federation>(this.getApiUrl(`/federations/${federation.id}`), federation);
  }

  public deleteFederation(federationId: string): Observable<string> {
    return this.httpClient.delete(this.getApiUrl(`/federations/${federationId}`)).pipe(map(() => federationId));
  }

  public getUsersForFederation(
    federationId: string,
    options: Partial<FederationUserFindOptions> = {
      offset: 0,
      limit: 25
    }
  ): Observable<Result<FederationUser>> {
    const finalOptions = {
      ...options,
      offset: options?.offset || 0,
      limit: options?.limit || 1,
      sort: `${options?.sort?.key || 'firstName'},${options?.sort?.order || 'asc'}`
    };

    const params = new HttpParams()
      .set('page', options.offset / options.limit)
      .set('size', options.limit)
      .set('sort', finalOptions.sort);

    return this.httpClient
      .post<{
        entities: FederationUser[];
        totalCount: number;
        filterCount: number;
        continuationToken: string;
      }>(this.getApiUrl(`/federations/${federationId}/users/search`), { filter: options.filter }, { params })
      .pipe(map(result => Utils.mapToResult<FederationUser>(result, options.offset)));
  }

  public getGroupsForFederation(
    federationId: string,
    options: Partial<FederationGroupFindOptions> = {
      offset: 0,
      limit: 25
    }
  ): Observable<Result<FederationGroup>> {
    const finalOptions = {
      ...options,
      offset: options?.offset || 0,
      limit: options?.limit || 1,
      sort: `${options?.sort?.key || 'displayName'},${options?.sort?.order || 'asc'}`
    };

    const params = new HttpParams()
      .set('page', options.offset / options.limit)
      .set('size', options.limit)
      .set('sort', finalOptions.sort);

    return this.httpClient
      .post<{
        entities: FederationGroup[];
        totalCount: number;
        filterCount: number;
        continuationToken: string;
      }>(this.getApiUrl(`/federations/${federationId}/groups/search`), { filter: options.filter }, { params })
      .pipe(map(result => Utils.mapToResult<FederationGroup>(result, options.offset)));
  }

  public generateToken(federationId: string, expiresAtUTC: Date): Observable<ProvisioningToken> {
    return this.httpClient.post<ProvisioningToken>(this.getApiUrl(`/federations/${federationId}/token`), { expiresAt: expiresAtUTC });
  }

  public getAutoProvisioningTokenConfiguration(): Observable<AutoProvisioningTokenConfiguration> {
    return this.httpClient.get<AutoProvisioningTokenConfiguration>(this.getApiUrl(`/federations/token/config`));
  }
}
