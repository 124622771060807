import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { ProvisioningType, UserDetails, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { AppState, UserService as SaccUserService, userActions } from '@celum/sacc/shared';

export interface ProfileViewModel {
  userName: string;
  canEdit: boolean;
  canDelete: boolean;
  isOwnerOfAnotherAccount: boolean;
  isManualProvisioned: boolean;
}

@Injectable()
export class ProfileService {
  public vm$ = this.userService.currentUser$.pipe(
    isTruthy(),
    distinctUntilChanged((previous, current) => JSON.stringify(previous) !== JSON.stringify(current)),
    map(currentUser => this.createViewModel(currentUser))
  );

  constructor(
    private userService: UserService,
    private store$: Store<AppState>,
    private saccUserService: SaccUserService
  ) {}

  public editProfile(): void {
    this.saccUserService.editProfile();
  }

  public deleteOwnProfile(): void {
    this.store$.dispatch(userActions.selfDeletion());
  }

  private createViewModel(currentUser: UserDetails): ProfileViewModel {
    return {
      userName: `${currentUser.firstName || ''} ${currentUser.lastName || ''}`,
      canEdit: !currentUser.provisioningType,
      isManualProvisioned: currentUser.provisioningType === ProvisioningType.MANUAL,
      canDelete: !currentUser.provisioningType || currentUser.provisioningType === ProvisioningType.MANUAL,
      isOwnerOfAnotherAccount: currentUser.accountAccesses?.some(accAccess => accAccess.ownerEmail === currentUser.email)
    };
  }
}
