<clickable-popup-dialog
  [contentClass]="'sacc-privilege-info-popup-dialog_dialog'"
  [triggerTooltipTranslationKey]="'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.TOOLTIP_PRIVILEGES_INFO_BUTTON'">
  <celum-icon
    class="sacc-clickable-popup-dialog_icon"
    popupTrigger
    [class.sacc-clickable-popup-dialog_icon--popup-open]=""
    [configuration]="iconQuestion"></celum-icon>
  <div popupContent (click)="$event.stopPropagation()">
    <div class="sacc-privilege-info-popup-dialog_menu" data-test-sacc-privilege-info-popup-dialog>
      <span class="sacc-privilege-info-popup-dialog_menu-text" data-test-sacc-privilege-info-popup-dialog-text>
        {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.INFO_PANEL.DESCRIPTION' | translate }}
      </span>
      <a class="sacc-privilege-info-popup-dialog_menu-link" data-test-sacc-privilege-info-popup-dialog-link [href]="urlTarget" target="_blank">
        {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.INFO_PANEL.LINK' | translate }}
      </a>
    </div>
  </div>
</clickable-popup-dialog>
