import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormControl, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LogLevel } from '@azure/msal-common';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie-service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import {
  ApiConfiguration,
  AUTH_SERVICE_CONFIG,
  AuthInterceptor,
  AuthInterceptorConfig,
  CelumAuthModule,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  ServiceTokenInterceptorConfig
} from '@celum/authentication';
import { CelumIconModule, CelumSnackbarModule } from '@celum/common-components';
import { CelumSnackbarListModule, CelumSystembarModule, SnackbarList } from '@celum/internal-components';
import { CustomTranslateLoader } from '@celum/ng2base';
import { AccountResolver } from '@celum/sacc/shared';
import { LoaderComponent } from '@celum/sacc/ui';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppStoreModule } from './modules/app-store.module';
import { AppErrorHandler } from './services/app-error-handler';
import { HttpErrorInterceptor } from './services/http-error-interceptor.service';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppStoreModule,
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    MatDialogModule,
    MatNativeDateModule,
    CelumIconModule,
    FormsModule,
    ReactiveFormsModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25, // Retains last 25 states
          connectInZone: true
        })
      : [],
    InfiniteScrollModule,
    ClipboardModule,
    CelumAuthModule.forRoot({
      logLevel: LogLevel.Error
    }),
    CelumSnackbarModule,
    CelumSnackbarListModule,
    CelumSystembarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translationFactory,
        deps: [HttpClient]
      }
    }),
    LoaderComponent
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: SERVICE_TOKEN_INTERCEPTOR_CONFIG,
      useFactory: serviceTokenInterceptorConfigFactory
    },
    {
      provide: AUTH_SERVICE_CONFIG,
      useValue: { loadSaccUserAfterSignIn: false }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { disableTooltipInteractivity: true, showDelay: 500 }
    },
    {
      provide: ErrorStateMatcher,
      useValue: {
        isErrorState: (control: FormControl | null, _: FormGroupDirective | NgForm | null) => (control.dirty || control.touched) && control.invalid
      }
    },
    AccountResolver,
    CookieService,
    MatDialogModule
  ],
  bootstrap: [AppComponent, SnackbarList]
})
export class AppModule {}

export function translationFactory(http: HttpClient): CustomTranslateLoader {
  return new CustomTranslateLoader(http, ['', 'common', 'shared', 'backend']);
}

function serviceTokenInterceptorConfigFactory(): ServiceTokenInterceptorConfig<AuthInterceptorConfig> {
  const emptyConfig = {
    apiConfigurations: [] as ApiConfiguration[],
    passThrough403Error: true
  };
  const getConfiguration = () => emptyConfig;

  return {
    getInterceptorConfiguration: () => getConfiguration()
  };
}
