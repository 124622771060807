import { Sort } from '@angular/material/sort';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BatchDTO, Repository } from '@celum/sacc/domain';

export const repositoryActions = createActionGroup({
  source: '[Repository]',
  events: {
    'Get One': props<{ repositoryId: string }>(),
    'Get One Success': props<{ repository: Repository }>(),
    'Get One Failure': props<{ error: any }>(),
    // prettier-ignore
    'Register': props<{ repository: Repository }>(),
    'Register Success': props<{ repository: Repository }>(),
    'Register Failure': props<{ error: any }>(),
    // prettier-ignore
    'Update': props<{ repository: Repository }>(),
    'Update Success': props<{ repository: Repository }>(),
    'Update Failure': props<{ error: any }>(),
    // prettier-ignore
    'Delete': props<{ repositoryId: string }>(),
    'Delete Success': props<{ repositoryId: string }>(),
    'Delete Failure': props<{ error: any }>(),
    'Fetch Batch': props<{ batchSize: number }>(),
    'Fetch Batch Success': props<{ batch: BatchDTO<Repository> }>(),
    'Fetch Batch Failure': props<{ error: any }>(),
    // prettier-ignore
    'Search': props<{ filter: string }>(),
    'Search Success': props<{ batch: BatchDTO<Repository> }>(),
    'Search Failure': props<{ error: any }>(),
    'Filter Changed': props<{ filter: string }>(),
    'Sort Changed': props<{ sort: Sort }>(),
    'Reset Repository Table': emptyProps(),
    'Reset Repository Table Filter': emptyProps(),
    'Request Safe Redirect': props<{ url: string; accountId?: string }>()
  }
});
