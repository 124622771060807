import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as i18nISOCountries from 'i18n-iso-countries';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '../store/app.state';
import { selectUserCurrentLocale } from '../store/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private countries$: Observable<i18nISOCountries.LocalizedCountryNames>;

  constructor(private store$: Store<AppState>) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    i18nISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    i18nISOCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    i18nISOCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

    this.countries$ = this.store$.select(selectUserCurrentLocale).pipe(map(locale => i18nISOCountries.getNames(locale)));
  }

  public getCountries(): Observable<i18nISOCountries.LocalizedCountryNames> {
    return this.countries$;
  }
}
