import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthService } from '@celum/authentication';
import { CelumPropertiesProvider } from '@celum/core';

import { FederationResourceService } from './federation-resource.service';

@Injectable({
  providedIn: 'root'
})
export class SaccAuthService {
  private static readonly TFP_CLAIM = 'tfp';
  private static readonly REDIRECT_URL_QUERY_PARAM = 'redirectURL';
  public editToken$: Observable<string>;

  constructor(
    private authService: AuthService,
    private federationService: FederationResourceService
  ) {
    this.editToken$ = this.authService.msalRedirectResponse$.pipe(
      filter(result => (result?.idTokenClaims as any)?.[SaccAuthService.TFP_CLAIM]?.includes('edit')),
      map(result => result.accessToken)
    );

    this.authService.onSignOut = () => this.onSignOut();

    this.authService.msalRedirectResponse$.subscribe(() => {
      // Used for the logout of WR: if the cancelled parameter is provided, log out the user
      // TODO: Remove once WR switched to the new authentication library
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get('cancelled')) {
        this.authService.signOut(queryParams.get(SaccAuthService.REDIRECT_URL_QUERY_PARAM)).subscribe();
      }

      // Store the federation cookie so that WR can still log in
      // TODO: Remove once WR switched to the new authentication library
      const account = this.authService.getMsalAccount();
      if (account) {
        this.federationService.storeUserFederationInCookie({
          email: account?.idTokenClaims.email as string,
          domainHint: account?.idTokenClaims.extension_domainHint as string,
          signInPolicy: account?.idTokenClaims[SaccAuthService.TFP_CLAIM]
        });
      }
    });
  }

  public editProfile(email: string): void {
    this.authService.signIn(`${CelumPropertiesProvider.properties.authentication.redirectTokenUri}/edit-profile`, 'B2C_1_edit_profile', undefined, email);
  }

  private onSignOut(): void {
    localStorage.clear();
    this.federationService.clearUserFederation();
  }
}
