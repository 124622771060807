import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountAccessStatus, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';

export const userGroupGuard = (userService = inject(UserService)): Observable<boolean> => {
  return userService.currentUser$.pipe(
    isTruthy(),
    map(user => {
      const filteredAccounts = user.accountAccesses.filter(account => account.status === AccountAccessStatus.ACTIVE);
      return filteredAccounts.length > 0;
    })
  );
};
