import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { AuthService, UserDetails } from '@celum/authentication';
import { AVATAR_SIZE, AvatarConfigBuilder, AvatarConfiguration, CelumAvatarModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { isTruthy } from '@celum/core';
import { AppState, notificationActions, selectUserCurrent, StorageUtils, userActions } from '@celum/sacc/shared';

export interface AvatarViewModel {
  avatarConfig: AvatarConfiguration;
}

@Component({
  selector: 'sacc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  // Needed to be able to override the background color of the avatar
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, StoreModule, CelumAvatarModule]
})
export class AvatarComponent {
  @Input() public editMode = true;

  public vm$ = this.store$.select(selectUserCurrent).pipe(
    isTruthy(),
    distinctUntilChanged(),
    switchMap(user => this.createViewModel(user))
  );

  private supportedExtensions = ['image/jpg', 'image/png', 'image/jpeg'];

  private icons: { [key: string]: IconConfiguration } = {
    edit: new IconConfiguration('edit-m').withColor(ColorConstants.SYSTEM_WHITE).withIconSize(32),
    add: new IconConfiguration('add-m').withColor(ColorConstants.SYSTEM_WHITE).withIconSize(32)
  };

  constructor(
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private authenticationService: AuthService
  ) {}

  public onSelectFile(event: any): void {
    // called each time file input changes

    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    const file: File = event.target.files[0];

    if (!this.supportedExtensions.includes(file.type)) {
      this.store$.dispatch(
        notificationActions.error({
          message: this.translateService.instant('COMPONENTS.AVATAR.ONLY_JPG_PNG_SUPPORTED')
        })
      );
      return;
    }

    // If file is larger than 300 kb
    if (file.size / 1024 > 300) {
      this.store$.dispatch(
        notificationActions.error({
          message: this.translateService.instant('COMPONENTS.AVATAR.SMALLER_THAN')
        })
      );
      return;
    }

    this.store$.dispatch(userActions.uploadProfilePicture({ file }));
  }

  private createViewModel(user: UserDetails): Observable<AvatarViewModel> {
    return this.authenticationService.getAuthResult().pipe(
      map(authResult => {
        const profilePicture = user.profilePictureDownloadLink
          ? `${user.profilePictureDownloadLink}&token=${StorageUtils.getTokenForId(user.oid, authResult.accessToken)}`
          : undefined;
        const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
        const configuration = new AvatarConfigBuilder()
          .withImage(profilePicture)
          .withSize(AVATAR_SIZE.xl)
          .withDisplayText(initials)
          .withBackgroundColor(ColorConstants.BLUE_GRAY_400);

        if (this.editMode) {
          configuration.withOverlayIcon(
            profilePicture ? this.icons.edit : this.icons.add,
            profilePicture ? ColorConstants.systemBlack(70) : ColorConstants.BLUE_GRAY_700
          );
        }

        return { avatarConfig: configuration.build() };
      })
    );
  }
}
