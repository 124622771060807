export interface Federation {
  id: string;
  name: string;
  domainHint: string;
  type: FederationType;
  provisioning?: ProvisioningConfiguration;
  associatedAccounts: AccountAssociation[];
}

export interface ProvisioningConfiguration {
  type: ProvisioningType;
  emailDomains: string[];
  provisioningIdentity: string;
  scimUrl: string;
  tokens?: ProvisioningTokenMask[];
}

export interface AccountAssociation {
  accountId: string;
  accountName: string;
}

export enum ProvisioningType {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic'
}

export enum FederationType {
  PUBLIC = 'public',
  PRIVATE = 'private'
}

export interface ProvisioningTokenMask {
  tokenId: string;
  tokenMask: string;
  expiresAt: string;
  expirationWarning: TokenExpirationWarning;
}

export interface ProvisioningToken {
  tokenId: string;
  expiresAt: string;
  token: string;
}

export type ProvisioningTokenItem = ProvisioningToken & Partial<ProvisioningTokenMask>;

export interface AutoProvisioningTokenConfiguration {
  minExpirationLeapMinutes: string;
  maxExpirationLeapMinutes: string;
}

export enum TokenExpirationWarning {
  NONE = 'NONE',
  WARNING = 'WARNING',
  EXPIRED = 'EXPIRED'
}
