import { Pipe, PipeTransform } from '@angular/core';

import { AccountUser } from '@celum/shared/domain';

@Pipe({
  name: 'userName',
  standalone: true
})
export class UserNamePipe implements PipeTransform {
  public transform(accountUser: AccountUser, placeholder?: string): string {
    if (accountUser.firstName && accountUser.lastName) {
      return `${accountUser.firstName} ${accountUser.lastName}`;
    }
    if (accountUser.firstName) {
      return accountUser.firstName;
    }
    if (accountUser.lastName) {
      return accountUser.lastName;
    }

    return placeholder || accountUser.email || '';
  }
}
