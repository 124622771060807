import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumButtonModule, CelumIconModule, IconConfiguration } from '@celum/common-components';

@Component({
  selector: 'sacc-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  standalone: true,
  // Needed to remove top margin from icon
  encapsulation: ViewEncapsulation.None,
  imports: [MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule, CelumButtonModule, CelumIconModule]
})
export class IconButtonComponent {
  @Input() public iconConfiguration: IconConfiguration;
  @Input() public disabled: boolean;
  @Input() public size: 'big' | 'medium' | 'small' = 'medium';
}
