import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService implements OnDestroy {
  private routerSubscription: Subscription;
  private appInsights: ApplicationInsights;

  constructor(private router: Router) {
    // We are initiating app insights only when instrumentationKey is set
    if (this.instrumentationKey) {
      this.initAppInsights();
      this.startLoggingPageViews();
    }
  }

  private get instrumentationKey(): string {
    return (window as any).Celum.properties.instrumentationKey;
  }

  public setUserId(userId: string): void {
    if (this.appInsights) {
      this.appInsights.setAuthenticatedUserContext(userId);
    }
  }

  public clearUserId(): void {
    if (this.appInsights) {
      this.appInsights.clearAuthenticatedUserContext();
    }
  }

  public logError(error: Error): void {
    // We are logging exceptions only if instrumentation key is set
    if (this.appInsights) {
      this.appInsights.trackException({
        exception: error
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private initAppInsights(): void {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.instrumentationKey
      }
    });

    this.appInsights.loadAppInsights();
  }

  private startLoggingPageViews(): void {
    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof ResolveEnd),
        map(event => event as ResolveEnd)
      )
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
        }
      });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({ name, uri });
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
