import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs/operators';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';
import { isTruthy } from '@celum/core';

import { ProfileService } from './profile.service';
import { AvatarComponent } from '../avatar/avatar.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { IconTextButtonComponent } from '../icon-text-button/icon-text-button.component';

@Component({
  selector: 'sacc-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  // Needed to style the mat-button
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatMenuModule, MatTooltipModule, CelumIconModule, AvatarComponent, IconButtonComponent, IconTextButtonComponent],
  providers: [ProfileService]
})
export class ProfileComponent {
  protected icons: { [key: string]: IconConfiguration } = {
    menu: IconConfiguration.large('option-m'),
    remove: IconConfiguration.medium('remove-l')
  };

  private supportUrl = 'https://service.celum.com/';

  constructor(
    private matDialog: MatDialog,
    private translateService: TranslateService,
    protected service: ProfileService
  ) {}

  protected editProfile(): void {
    this.service.editProfile();
  }

  protected deleteProfile(): void {
    this.service.vm$
      .pipe(
        map(vm => vm.isOwnerOfAnotherAccount),
        take(1)
      )
      .subscribe(isAccountOwner => {
        this.showDeleteProfileDialog(isAccountOwner);
      });
  }

  private showDeleteProfileDialog(isOwnerOfAnotherAccount: boolean): void {
    const dialogConfig: MatDialogConfig = {
      autoFocus: false
    };

    const scenarioNumber = isOwnerOfAnotherAccount ? '1' : '2';

    dialogConfig.data = {
      title: this.translateService.instant(`COMPONENTS.PROFILE.PROFILE_DELETION.SCENARIO${scenarioNumber}.HEADER`),
      caption: this.translateService.instant(`COMPONENTS.PROFILE.PROFILE_DELETION.SCENARIO${scenarioNumber}.CAPTION`),
      description: this.translateService.instant(`COMPONENTS.PROFILE.PROFILE_DELETION.SCENARIO${scenarioNumber}.DESCRIPTION`),
      type: 'warning',
      cancelButtonText: this.translateService.instant(`COMPONENTS.PROFILE.PROFILE_DELETION.SCENARIO${scenarioNumber}.CANCEL`),
      confirmationButtonText: this.translateService.instant(`COMPONENTS.PROFILE.PROFILE_DELETION.SCENARIO${scenarioNumber}.CONFIRM`)
    };

    this.matDialog
      .open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(isTruthy())
      .subscribe(() => {
        // Navigate to support page if the user is still owner of another account (cannot be deleted yet in that case), otherwise initialize user deletion
        if (isOwnerOfAnotherAccount) {
          window.open(this.supportUrl, '_blank');
        } else {
          this.service.deleteOwnProfile();
        }
      });
  }
}
