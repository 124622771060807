import { Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, mergeMap, take, tap } from 'rxjs/operators';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';
import { UserExistsResponse } from '@celum/sacc/domain';
import { Utils } from '@celum/sacc/shared';

@Component({
  selector: 'sacc-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, MatAutocompleteModule, MatFormFieldModule, MatIconModule, MatInputModule, CelumIconModule],
  providers: [
    {
      provide: ErrorStateMatcher,
      useValue: {
        isErrorState: (control: FormControl | null) => control.touched && control.invalid
      }
    }
  ]
})
export class EmailInputComponent implements OnInit {
  @Input() public validationWarningMessage: string;
  @Input() public emailPlaceHolder: string;
  @Input() public emailHint: string;
  @Input() public emailStatusIndicator: string;
  @Input() public emailFiltered: (email: string) => boolean;
  @Input() public emailExists: (email: string, forDeletion?: boolean) => Observable<UserExistsResponse>;
  @Input() public showEmail: (isNew: boolean, email: string) => boolean;
  @Input() public showValidationWarning: (isNew: boolean, email: string) => boolean;
  @Input() public label: string;
  @Input() public required = true;

  @Output() public readonly emailChanged = new EventEmitter();

  @ViewChild('emailInput', { static: true }) public emailInput: ElementRef;

  public autocompleteCfg = {
    isNew: true,
    email: ''
  };

  protected email = new FormControl('', Validators.email);

  protected emailIcon = IconConfiguration.medium('mail-m');

  protected emailTextChanged = new Subject();

  private destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.emailTextChanged
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(500),
        tap(() => {
          // If autocomplete is shown, but the input value is changing. Just reset it
          if (this.autocompleteCfg.email) {
            this.resetAutocompleteCfg();
          }
        }),
        map(() => this.email),
        filter(email => Utils.isEmail(email.value) && this.emailFiltered(email.value)),
        mergeMap(email =>
          this.emailExists(email.value).pipe(
            take(1),
            catchError(() => EMPTY),
            finalize(() => (this.autocompleteCfg.email = email.value))
          )
        )
      )
      .subscribe(response => (this.autocompleteCfg.isNew = !response || !response.exists));
  }

  public addEmail(): void {
    const email = (this.email.value || '').trim();

    if (email) {
      this.emailChanged.emit(email);
    }

    this.reset();
  }

  private reset(): void {
    this.email.setValue('');
    this.resetAutocompleteCfg();
  }

  private resetAutocompleteCfg(): void {
    this.autocompleteCfg.email = '';
    this.autocompleteCfg.isNew = true;
  }
}
