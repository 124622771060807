import jwtDecode from 'jwt-decode';

/**
 * Item stored in storage, but serialized into JSON
 */
export interface StorageItem {
  item: any;
  created: number;
  expiration: number;
}

export class StorageUtils {
  /**
   * Default lifetime of stored item unless you specify otherwise
   */
  public static readonly DEFAULT_LIFETIME = 5 * 60 * 1000;

  /**
   * Puts item to storage with expiration timestamp
   * @param name - key of the item in storage
   * @param item - actual item to be stored
   * @param lifetime - milliseconds that item will be valid for
   */
  public static setItem(name: string, item: string, lifetime: number = StorageUtils.DEFAULT_LIFETIME): void {
    // Do nothing if name or item was not provided
    if (!name || !item) {
      return;
    }

    // Get current timestamp
    const currentTimestamp = Date.now();

    // Create object with the specified item, add created & expiration date
    const itemObject: StorageItem = {
      item,
      created: currentTimestamp,
      expiration: currentTimestamp + lifetime
    };

    // Serialize it to json
    const jsonItemObject = JSON.stringify(itemObject);

    // Put it to storage
    localStorage.setItem(name, jsonItemObject);
  }

  /**
   * Try to find item in storage
   * @param name - Key of the stored item in storage
   * @return Found, unexpired item in storage. Null otherwise
   */
  public static getItem(name: string): string {
    // Get item from storage
    const jsonItemObject = localStorage.getItem(name);

    // Do nothing if nothing was found
    if (!jsonItemObject) {
      return null;
    }

    // Deserialize item
    const itemObject: StorageItem = JSON.parse(jsonItemObject);

    // Get current timestamp
    const currentTimestamp = Date.now();

    // If the item has expired, remove it from storage and return null
    if (currentTimestamp > itemObject.expiration) {
      localStorage.removeItem(name);
      return null;
    }

    // Return found item
    return itemObject.item;
  }

  public static getTokenForId(id: string, newToken: string): string {
    // in case there exists no token for the id, return no token
    if (!newToken) {
      return null;
    }

    const oldToken = StorageUtils.getItem(id);
    // if there is no token in the local storage, save the new one
    if (!oldToken) {
      const claims: any = jwtDecode(newToken);
      const properties = (window as any).Celum.properties;
      let expiresAt = claims.exp;

      if (!expiresAt) {
        expiresAt = Date.now() / 1000 + properties.mockTokenDefaultExpiration * 1000;
      }
      const currentTimeStamp = Date.now();
      const tokenExpirationOffset = properties.tokenExpirationOffset;
      // throw away token earlier, such that expiration does not cause problems with downloading
      StorageUtils.setItem(id, newToken, expiresAt * 1000 - currentTimeStamp - tokenExpirationOffset * 1000);
      return newToken;
    }
    return oldToken;
  }
}
