import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

import { DateService, DateUtil } from '@celum/ng2base';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

  public getFirstDayOfWeek(): number {
    return 1;
  }

  public format(date: Date, displayFormat: any): string {
    if (displayFormat === 'input') {
      return DateService.handleDateFormat(date, DateService.getFormat());
    } else {
      return DateUtil.getFormattedDate(date, DateUtil.FORMATS.DATE.NAMED_SHORT, DateService.GLOBAL_LOCALE);
    }
  }

  /**
   * Parses a date from a user-provided value.
   *
   * We override the parse method and delegate to date-fns because the NativeDateAdapter can't parse date in different (e.g. german) locales.
   *
   * @param value The value to parse.
   * @returns The parsed date.
   */

  public parse(value: any): Date | null {
    if (value) {
      return DateService.convertToDateNumeric(value);
    } else {
      return null;
    }
  }
}
