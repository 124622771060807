import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private sanitizer: DomSanitizer) {}

  public readAsDataUrl(blob: Blob): Observable<string> {
    const subject = new ReplaySubject<string>();
    const reader = new FileReader();

    // called once readAsDataURL is completed
    reader.onload = (e: any) => {
      const safe = this.sanitizer.bypassSecurityTrustUrl(`${e.target.result}`) as string;
      subject.next(safe);
      subject.complete();
    };

    reader.readAsDataURL(blob);
    return subject;
  }
}
