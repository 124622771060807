import { Pipe, PipeTransform } from '@angular/core';

import { AVATAR_SIZE, AvatarConfigBuilder, AvatarConfiguration, ColorConstants } from '@celum/common-components';
import { FederationGroup, UserGroup } from '@celum/sacc/domain';

@Pipe({
  name: 'groupToAvatarConfig',
  standalone: true
})
export class GroupToAvatarConfigPipe implements PipeTransform {
  public transform(group: UserGroup | FederationGroup, size: AVATAR_SIZE = AVATAR_SIZE.m, showTooltip = true): AvatarConfiguration {
    return userGroupToConfig(group, size, showTooltip);
  }
}

@Pipe({
  name: 'groupsToAvatarConfigs',
  standalone: true
})
export class GroupsToAvatarConfigsPipe implements PipeTransform {
  public transform(groups: UserGroup[] | FederationGroup[], size: AVATAR_SIZE = AVATAR_SIZE.m, showTooltip = true): AvatarConfiguration[] {
    return groups?.map(group => userGroupToConfig(group, size, showTooltip)) || [];
  }
}

function userGroupToConfig(group: UserGroup | FederationGroup, size: AVATAR_SIZE, showTooltip: boolean): AvatarConfiguration {
  const name = (group as UserGroup)?.name || (group as FederationGroup)?.displayName;
  return (
    new AvatarConfigBuilder()
      .withBackgroundColor(ColorConstants.BLUE_GRAY_400)
      .withAvatarTextPlaceholder(name?.trim()[0].toUpperCase())
      .withDisplayText(name)
      .withTooltip(showTooltip ? name : null)
      .withSize(size)
      // Needed to correctly style group avatars in the "show more" dropdown of the avatar list
      .withClass('sacc-group-avatar')
      .build()
  );
}
