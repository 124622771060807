import { createSelector } from '@ngrx/store';

import { Account } from '@celum/sacc/domain';

import { accountAdapter, AccountState } from './account.state';
import { Constants } from '../../constants';
import { AppState } from '../app.state';

export const selectAccountState = (state: AppState): AccountState => state.accounts;

export const selectAccountsAll = createSelector(selectAccountState, accountAdapter.getSelectors().selectAll);

export const selectAccountsLoading = createSelector(selectAccountState, accountsState => accountsState.loading);

export const selectAccountsAllLoaded = createSelector(
  selectAccountState,
  selectAccountsAll,
  (accountsState, allAccounts) => allAccounts.length > 0 && !accountsState.continuationToken
);

export const selectAccountById = (accountId: string) =>
  createSelector(selectAccountState, (accountsState: AccountState): Account => accountsState.entities[accountId]);

export const selectAccountNextBatchParams = createSelector(selectAccountState, accountState => {
  return {
    count: accountAdapter.getSelectors().selectAll(accountState).length,
    sort: accountState.sort,
    continuationToken: accountState.continuationToken
  };
});

export const selectAccountActiveAccountId = createSelector(selectAccountState, accountsState => accountsState.selectedAccountId);

export const selectAccountSharedAccessLink = createSelector(
  selectAccountActiveAccountId,
  activeAccountId => `${(window as any).Celum.properties.frontendUrl}home?${Constants.REQUEST_ACCOUNT_ACCESS_QUERY_PARAM}=${activeAccountId}`
);

export const selectAccountActiveAccount = createSelector(
  selectAccountState,
  selectAccountActiveAccountId,
  (accountsState, activeAccountId) => accountsState.entities[activeAccountId]
);

export const selectAccountCount = createSelector(selectAccountState, accountState => accountState.totalCount);

export const selectAccountFilterCount = createSelector(selectAccountState, accountState => accountState.filterCount);

export const selectAccountFilter = createSelector(selectAccountState, accountState => accountState.filter);
