import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';

export enum PrivilegeIconType {
  WORK = 'work',
  EXPERIENCE = 'experience',
  EXPERIENCE_NOT_APPLIED = 'experience-not-applied'
}

@Component({
  selector: 'sacc-privilege-icon',
  templateUrl: './privilege-icon.component.html',
  styleUrl: 'privilege-icon.component.scss',
  standalone: true,
  imports: [MatTooltipModule, CelumIconModule]
})
export class PrivilegeIconComponent implements OnChanges {
  @Input() public iconType: PrivilegeIconType;
  @Input() public showIndicator = false;
  @Input() public disabled = false;
  @Input() public tooltip: string;

  protected privilegeIndicatorTooltip?: string;
  protected privilegeIndicatorIcon = new IconConfiguration('privilege-indicator').withIconDimensions(10, 8);
  protected icon: IconConfiguration;

  public ngOnChanges({ iconType, disabled }: SimpleChanges): void {
    if (this.iconType && (this.iconType !== iconType?.previousValue || this.disabled !== disabled?.currentValue)) {
      this.icon = IconConfiguration.large(`logo-${this.iconType}`);
      this.privilegeIndicatorTooltip = `COMPONENTS.PRIVILEGE_ICON.TOOLTIP_PRIVILEGE_INDICATOR_${this.iconType.toUpperCase()}`;
    }
  }
}
