import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

import { AccountAccess, AccountUserRole, UserDetails } from '@celum/authentication';
import { tapAllResponses } from '@celum/core';
import { SaccPaginationResult, UserGroup } from '@celum/sacc/domain';
import { UserGroupResourceService } from '@celum/sacc/shared';
import { ErrorService, PagedListState, Result, SimplePagedListService } from '@celum/shared/util';

export type UserGroupsListState = {
  allLoaded: boolean;
  loading: boolean;
  accountId?: string;
  user?: UserDetails;
  isReadonly: boolean;
  hasBottom?: boolean;
  displayedColumns: string[];
  searchString: string;
  firstNotZeroUserGroupCount: number;
  userGroupsCount: number;
  maxGroupCount?: number;
};

export type UserGroupsListViewModel = {
  userGroups: UserGroup[];
  activeAccountAccess: AccountAccess;
  userGroupsCountFiltered: number;
  userCanManageGroup: boolean;
} & UserGroupsListState;

const USER_GROUPS_LIST_SERVICE = 'UserGroupsListService';

@Injectable()
export class UserGroupsListService extends SimplePagedListService<UserGroup, UserGroupsListState> {
  public vm$ = this.select(state => this.createViewModel(state));

  public deleteUserGroup = this.effect<string>(group$ =>
    group$.pipe(
      switchMap(id => this.userGroupService.deleteUserGroup(this.get().accountId, id).pipe(map(() => id))),
      tapAllResponses(
        () => this.load(),
        (error: HttpErrorResponse) => {
          this.errorService.httpError(error, USER_GROUPS_LIST_SERVICE);
        }
      )
    )
  );

  constructor(
    private userGroupService: UserGroupResourceService,
    private errorService: ErrorService
  ) {
    super(
      {
        serverCall: (offset: number, batchSize: number) => this.loadUserGroups(offset, batchSize),
        batchSize: 20
      },
      {
        allLoaded: false,
        loading: false,
        isReadonly: false,
        displayedColumns: [],
        firstNotZeroUserGroupCount: 0,
        searchString: '',
        userGroupsCount: 0
      }
    );
  }

  public setAccountId(accountId: string): void {
    this.patchState({ accountId, firstNotZeroUserGroupCount: 0 });
    if (accountId && this.get().user) {
      this.load();
    }
  }

  public setUser(user: UserDetails): void {
    this.patchState({ user });
    if (user && this.get().accountId) {
      this.load();
    }
  }

  public init(displayedColumns: string[]): void {
    this.patchState({ displayedColumns });
  }

  public searchChanged(event: string): void {
    this.patchState({ searchString: event });
    this.load();
  }

  private loadUserGroups(offset: number, batchSize: number): Observable<Result<UserGroup>> {
    this.patchState({ loading: true });
    return this.userGroupService
      .getUserGroupsForAccount(this.get().accountId, {
        offset,
        limit: batchSize,
        filter: this.get().searchString
      })
      .pipe(
        tap(result => {
          this.patchState({
            allLoaded: !result.paginationInfo.hasBottom,
            hasBottom: result.paginationInfo.hasBottom
          });
        }),
        switchMap(result =>
          this.userGroupService.getUserGroupCount(this.get().accountId).pipe(
            tap(userGroupCount => {
              if (this.get().firstNotZeroUserGroupCount === 0) {
                this.patchState({
                  firstNotZeroUserGroupCount: userGroupCount.count
                });
              }
              this.patchState({
                userGroupsCount: userGroupCount.count,
                maxGroupCount: userGroupCount.maxGroupCount,
                loading: false
              });
            }),
            map(() => result)
          )
        ),
        catchError(error => {
          this.errorService.error('UserGroupListService', '', error, false, true);
          this.patchState({ loading: false, hasBottom: false });
          return of({
            data: [],
            paginationInfo: {
              hasBottom: false,
              hasTop: false,
              totalElementCount: 0,
              filterCount: 0
            }
          });
        })
      );
  }

  private createViewModel(state: PagedListState<UserGroup> & UserGroupsListState): UserGroupsListViewModel {
    const activeAccountAccess = state.user?.accountAccesses.find(access => access.accountId === state.accountId);
    return {
      ...state,
      userGroups: state.data,
      userGroupsCountFiltered: (state.paginationInfo as SaccPaginationResult)?.totalElementCount || 0,
      userCanManageGroup: state.user?.admin || activeAccountAccess?.role === AccountUserRole.MANAGER,
      activeAccountAccess
    };
  }
}
