<div class="landing-page">
  <shared-systembar [configuration]="systembarConfiguration"></shared-systembar>

  <mat-drawer-container class="app-navigation">
    <mat-drawer-content>
      <mat-drawer-container class="user-navigation">
        <mat-drawer mode="side" opened #drawer>
          <sacc-profile></sacc-profile>
          @if (vm$ | async; as vm) {
            <nav>
              <a class="app-navigation_item" routerLink="/home" [routerLinkActive]="'app-navigation_item--active'">
                {{ 'COMPONENTS.LANDING_PAGE.HOME' | translate }}
              </a>
              @if (vm.canOpenMyOrg) {
                <a class="app-navigation_item" routerLink="/myaccount" [routerLinkActive]="'app-navigation_item--active'">
                  {{ 'COMPONENTS.LANDING_PAGE.MY_ORGANIZATION' | translate }}
                </a>
              }
              @if (vm.canOpenAccountMembership) {
                <a class="app-navigation_item" routerLink="/account-membership" [routerLinkActive]="'app-navigation_item--active'">
                  {{ 'COMPONENTS.LANDING_PAGE.ACCOUNT_MEMBERSHIP' | translate }}
                </a>
              }
              @if (vm.canOpenUserGroups) {
                <a class="app-navigation_item" routerLink="/user-groups" [routerLinkActive]="'app-navigation_item--active'">
                  {{ 'COMPONENTS.LANDING_PAGE.USER_GROUPS' | translate }}
                </a>
              }
              @if (vm.adminLoggedIn) {
                <a class="app-navigation_item" routerLink="/accounts" [routerLinkActive]="'app-navigation_item--active'">
                  {{ 'COMPONENTS.LANDING_PAGE.ACCOUNT_ADMINISTRATION' | translate }}
                </a>
              }
              @if (vm.adminLoggedIn) {
                <a class="app-navigation_item" routerLink="/repositories" [routerLinkActive]="'app-navigation_item--active'">
                  {{ 'COMPONENTS.LANDING_PAGE.REPOSITORY_ADMINISTRATION' | translate }}
                </a>
              }
              @if (vm.adminLoggedIn) {
                <a class="app-navigation_item" routerLink="/federations" [routerLinkActive]="'app-navigation_item--active'">
                  {{ 'COMPONENTS.LANDING_PAGE.FEDERATION_ADMINISTRATION' | translate }}
                </a>
              }
            </nav>
          }
          <app-collapse-button [opened]="drawer.opened" (collapse)="drawer.toggle()"></app-collapse-button>
        </mat-drawer>
        <mat-drawer-content class="inner-drawer-content">
          <router-outlet></router-outlet>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
