import { Component, Input, ViewEncapsulation } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { CelumAvatarModule } from '@celum/common-components';
import { CelumPipesModule } from '@celum/ng2base';

@Component({
  selector: 'sacc-table-count',
  templateUrl: './table-count.component.html',
  styleUrls: ['./table-count.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [StoreModule, CelumAvatarModule, CelumPipesModule]
})
export class TableCountComponent {
  @Input() public countAsParameter = true;
  @Input() public count: number;
  @Input() public label: string;
  @Input() public labels: string;
}
