import { createReducer, on } from '@ngrx/store';

import { userActions } from './user.action';
import { initialUserState } from './user.state';

export const usersReducer = createReducer(
  initialUserState,
  on(userActions.logInFailure, (state, { authError }) => ({
    ...state,
    currentUser: null,
    authError
  })),
  on(userActions.finishLoginFailure, state => ({
    ...state,
    currentUser: null
  })),
  on(userActions.finishLoginSuccess, userActions.getDetailsSuccess, userActions.updateSuccess, userActions.updateUserLanguageSuccess, (state, { user }) => ({
    ...state,
    currentUser: user
  })),
  on(userActions.uploadProfilePictureSuccess, (state, { downloadLink }) => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      profilePictureDownloadLink: downloadLink
    }
  }))
);
