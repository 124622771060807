import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { AuthService } from '@celum/authentication';
import { AppState, notificationActions, SaccAuthService, SaccHttpHeaders, SaccHttpHeaderValues, selectUserCurrent } from '@celum/sacc/shared';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private store$: Store<AppState>,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private saccAuthService: SaccAuthService
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.getToken(req).pipe(
      take(1),
      mergeMap(token =>
        next.handle(this.cloneHttpRequest(req, token)).pipe(
          tap(
            () => undefined,
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status === 401 && err.headers.get(SaccHttpHeaders.ERROR_KEY) === SaccHttpHeaderValues.TOKEN_EXPIRED) {
                  this.document.location.reload();
                } else if (err.status === 403) {
                  if (this.router.url !== '/home') {
                    this.router.navigate(['/home']);
                  }
                } else if (err.status === 404) {
                  this.handleNotFoundError();
                }
              }
            }
          )
        )
      )
    );
  }

  private handleNotFoundError(): void {
    this.store$
      .select(selectUserCurrent)
      .pipe(
        take(1),
        filter(u => !!u)
      )
      .subscribe(() => {
        this.store$.dispatch(
          notificationActions.error({
            message: this.translateService.instant('HTTP_INTERCEPTOR.NOT_FOUND')
          })
        );
        if (this.router.url !== '/home') {
          this.router.navigate(['/home']);
        }
      });
  }

  // Include authorization header only for request to our API
  private cloneHttpRequest(req: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    if (accessToken && req.url.includes(`${(window as any).Celum.properties.apiUrl}`)) {
      return req.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
    }

    return req;
  }

  private getToken(req: HttpRequest<any>): Observable<string> {
    if (!req.url.startsWith('http')) {
      return of(null);
    }
    if (req.url.includes('/federations/signIn') || req.url.includes('/federations/signUp')) {
      return of(null);
    }
    if (req.url.endsWith('users/current') && req.method === 'PUT' && !('locale' in req.body)) {
      // on edit, return edit IdToken
      return this.saccAuthService.editToken$;
    }
    return this.authService.getAuthResult().pipe(map(r => r?.accessToken || ''));
  }
}
