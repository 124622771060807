import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '@celum/ng2base';

import { notificationActions } from '../store/notification/notification.action';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private store$: Store<AppState>,
    private translateService: TranslateService
  ) {}

  public info(message: string, translationParams?: object): void {
    this.store$.dispatch(notificationActions.info({ message: this.translateService.instant(message, translationParams) }));
  }
}
