import { Sort } from '@angular/material/sort';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Account, BatchDTO, WorkRoomsPlanType } from '@celum/sacc/domain';

export const accountActions = createActionGroup({
  source: '[Account]',
  events: {
    // prettier-ignore
    'Create': props<{ account: Account }>(),
    'Create Success': props<{ account: Account }>(),
    'Create Failure': props<{ error: any }>(),
    'Sort Changed': props<{ sort: Sort }>(),
    'Fetch Batch': emptyProps(),
    'Fetch Batch Success': props<{ batch: BatchDTO<Account> }>(),
    'Fetch Batch Failure': props<{ error: any }>(),
    'Filter Changed': props<{ filter: string }>(),
    // prettier-ignore
    'Search': props<{ filter: string }>(),
    'Search Success': props<{ batch: BatchDTO<Account> }>(),
    'Search Failure': props<{ error: any }>(),
    'Reset Account Table Filter': emptyProps(),
    'Reset Account Table': emptyProps(),
    'Get One': props<{ id: string }>(),
    'Get One Success': props<{ account: Account }>(),
    'Get One Failure': props<{ error: any }>(),
    // prettier-ignore
    'Update': props<{ account: Account; logoFile?: File }>(),
    'Update Company Details': props<{ account: Account; logoFile?: File }>(),
    'Update Company Details Success': props<{ account: Account }>(),
    'Update Company Details Failure': props<{ error: any }>(),
    'Selected Account Changed': props<{ accountId: string; resetFilters: boolean; resetTables: boolean; skipLoadingDetails: boolean }>(),
    'Upload Logo': props<{ account: Account; file: File }>(),
    'Request New Plan For Workrooms': props<{ accountId: string; workRoomsPlanType: WorkRoomsPlanType }>(),
    'Request New Plan For Workrooms Success': emptyProps(),
    'Request New Plan For Workrooms Failure': props<{ error: any }>(),
    'Request Contenthub Demo': props<{ accountId: string }>(),
    'Request Contenthub Demo Success': emptyProps(),
    'Request Contenthub Demo Failure': props<{ error: any }>()
  }
});
