import { AccountAccess, TrialPlan } from '@celum/authentication';

export class TypeUtil {
  public static getAccountAccessOrTrialPlanId(account: AccountAccess | TrialPlan): string {
    return (account as AccountAccess).accountId || (account as TrialPlan).accountRequestId;
  }

  public static getAccountAccessOrTrialPlanName(account: AccountAccess | TrialPlan): string {
    return (account as AccountAccess).accountName || (account as TrialPlan).accountRequestOrgName;
  }
}
