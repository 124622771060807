import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { UserService as AuthenticationUserService } from '@celum/authentication';
import { isTruthy, tapAllResponses } from '@celum/core';
import { ErrorService } from '@celum/shared/util';

import { SaccAuthService } from './auth.service';
import { LoaderService } from './loader.service';
import { NotificationService } from './notification.service';
import { UserResourceService as SaccUserService } from './user-resource.service';
import { ErrorFactory } from '../error.factory';

@Injectable({ providedIn: 'root' })
export class UserService extends ComponentStore<any> {
  public editProfile = this.effect(operation$ =>
    operation$.pipe(
      tap(() => this.loaderService.show()),
      switchMap(() => this.authUserService.currentUser$),
      // Redirect to the edit-page
      tap(currentUser => this.saccAuthService.editProfile(currentUser.email))
    )
  );

  constructor(
    private loaderService: LoaderService,
    private saccAuthService: SaccAuthService,
    private saccUserService: SaccUserService,
    private router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private errorService: ErrorService,
    private authUserService: AuthenticationUserService
  ) {
    super({});

    this.saccAuthService.editToken$
      .pipe(
        isTruthy(),
        distinctUntilChanged(),
        switchMap(() => this.saccUserService.updateUser()),
        tapAllResponses(
          () => {
            this.router.navigate(['/home'], { replaceUrl: true });

            this.notificationService.info('SERVICES.USER.EFFECTS.PROFILE_UPDATE_SUCCESS');
          },
          error => this.handleOperationFailure(error, 'SERVICES.ACCOUNT_MEMBER.EFFECTS.INVITATION.RESEND_FAILURE')
        )
      )
      .subscribe();
  }

  private handleOperationFailure(error: any, messageKey: string): void {
    const specialErrorMessage = ErrorFactory.getErrorMessage(error, this.translateService);
    const specialError = specialErrorMessage ?? { error: specialErrorMessage };

    this.errorService.error('UserService', this.translateService.instant(messageKey, specialError), error);
    this.loaderService.hide();
  }
}
