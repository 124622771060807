import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { SaccHttpHeaders } from './sacc.http.headers';

export class ErrorFactory {
  public static getErrorMessage(error: any, translateService?: TranslateService): any {
    if (!error) {
      return '';
    }

    console.error(error);
    const defaultMsg = translateService ? translateService.instant('default-error') : 'default-error';
    if (error instanceof HttpErrorResponse) {
      const errorKey = error.headers.get(SaccHttpHeaders.ERROR_KEY);
      if (errorKey) {
        const errorMsg = translateService ? translateService.instant(errorKey) : errorKey;
        return !errorMsg || (translateService && errorMsg === errorKey) ? defaultMsg : errorMsg;
      }

      // Do not show details about input validation errors in the snackbar
      if (error.status === 400 && error.error.errorList) {
        return '';
      }
    }

    return defaultMsg;
  }

  public static getErrorKey(error: any): string {
    if (!error || !(error instanceof HttpErrorResponse)) {
      return '';
    }

    return error.headers.get(SaccHttpHeaders.ERROR_KEY);
  }
}
