<div class="sacc-privilege-icon" [class.sacc-privilege-icon--disabled]="disabled">
  <celum-icon
    class="sacc-privilege-icon_main-icon"
    data-test-sacc-privilege-icon
    [clickable]="false"
    [configuration]="icon"
    [matTooltip]="tooltip | translate"></celum-icon>
  @if (showIndicator) {
    <celum-icon
      class="sacc-privilege-icon_privilege-indicator"
      data-test-sacc-privilege-icon-indicator
      [clickable]="false"
      [configuration]="privilegeIndicatorIcon"
      [matTooltip]="privilegeIndicatorTooltip | translate"></celum-icon>
  }
</div>
