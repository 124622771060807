import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { AuthService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { AppState, Constants, FederationResourceService, RepositoryResourceService, selectUserCurrent, StorageUtils } from '@celum/sacc/shared';

@Injectable({
  providedIn: 'root'
})
export class QueryParamGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private repoService: RepositoryResourceService,
    private store: Store<AppState>,
    private federationService: FederationResourceService
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivateBase(next);
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivateBase(childRoute);
  }

  private canActivateBase(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const newTrial = next.queryParamMap.get(Constants.NEW_TRIAL_QUERY_PARAM);
    const invitationId = next.queryParamMap.get(Constants.INVITATION_ID_QUERY_PARAM);
    const requestAccess = next.queryParamMap.get(Constants.REQUEST_ACCOUNT_ACCESS_QUERY_PARAM);
    const connectViaRepo = next.queryParamMap.get(Constants.CONNECT_VIA_REPO_QUERY_PARAM);
    const redirectURL = next.queryParamMap.get(Constants.REDIRECT_URL_QUERY_PARAM);

    const shouldRedirectImmediately = redirectURL && !newTrial && !invitationId && !requestAccess && !connectViaRepo;

    invitationId && StorageUtils.setItem(Constants.INVITATION_ID_QUERY_PARAM, invitationId);
    if (requestAccess) {
      StorageUtils.setItem(Constants.REQUEST_ACCOUNT_ACCESS_QUERY_PARAM, requestAccess);
    } else if (newTrial !== null) {
      localStorage.setItem(Constants.NEW_TRIAL_QUERY_PARAM, 'true');
    } else if (connectViaRepo) {
      sessionStorage.setItem(Constants.CONNECT_VIA_REPO_QUERY_PARAM, connectViaRepo);
    }
    redirectURL && sessionStorage.setItem(Constants.REDIRECT_URL, redirectURL);

    return this.authService.isAuthenticated$.pipe(
      isTruthy(),
      switchMap(() =>
        this.store.select(selectUserCurrent).pipe(
          filter(cu => !!cu),
          take(1)
        )
      ),
      switchMap(user => {
        return shouldRedirectImmediately
          ? this.repoService.checkUrlAndRedirect(redirectURL, this.federationService.getSignInFederation(user.email)).pipe(map(() => true))
          : of(true);
      })
    );
  }
}
