import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountAccessStatus, AccountUserRole, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { ErrorService } from '@celum/shared/util';

export const hasAccessToAccountGuard = (
  route: ActivatedRouteSnapshot,
  _: RouterStateSnapshot,
  userService = inject(UserService),
  router = inject(Router),
  errorService = inject(ErrorService)
): Observable<boolean | UrlTree> => {
  return userService.currentUser$.pipe(
    isTruthy(),
    map(user => {
      const accountId = route.paramMap.get('id');
      const foundAccount = user.accountAccesses.find(account => account.accountId === accountId);
      if (!!foundAccount && foundAccount.status === AccountAccessStatus.ACTIVE && foundAccount.role === AccountUserRole.MANAGER) {
        return true;
      }

      errorService.error('HasAccessToAccountGuard', 'HTTP_INTERCEPTOR.NOT_FOUND');

      return router.parseUrl('/home');
    })
  );
};
