@if (service.vm$ | async; as vm) {
  <div class="sacc-profile" data-test-sacc-profile>
    <sacc-avatar data-test-sacc-profile-avatar></sacc-avatar>
    <p class="sacc-profile_user-name" data-test-sacc-profile-user-name>{{ vm.userName }}</p>
    <!-- Will be hidden to reserve the space -->
    <div class="sacc-profile_edit" [style.visibility]="vm.canEdit || vm.canDelete ? 'visible' : 'hidden'">
      <sacc-icon-text-button
        data-test-sacc-profile-button-edit
        [disabled]="!vm.canEdit || vm.isManualProvisioned"
        [icon]="'user-profile'"
        [label]="'COMPONENTS.PROFILE.EDIT_PROFILE'"
        [matTooltip]="vm.isManualProvisioned ? ('COMPONENTS.PROFILE.EDIT_PROFILE_DISABLED_MANUAL_PROVISIONED_TOOLTIP' | translate) : null"
        [size]="'medium'"
        (clicked)="editProfile()"></sacc-icon-text-button>
      @if (vm.canDelete) {
        <sacc-icon-button
          class="sacc-profile_menu-button"
          data-test-sacc-profile-button-actions
          [iconConfiguration]="icons.menu"
          [matMenuTriggerFor]="menu"
          [matTooltip]="'COMPONENTS.TABLE.TOOLTIPS.MORE_ACTIONS' | translate"></sacc-icon-button>
      }
      <mat-menu #menu="matMenu">
        <ng-container>
          <button data-test-sacc-profile-button-delete mat-menu-item (click)="deleteProfile()">
            <celum-icon [configuration]="icons.remove"></celum-icon>
            <span>{{ 'COMPONENTS.PROFILE.DELETE_PROFILE' | translate }}</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>
}
