import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AccountAccessStatus } from '@celum/authentication';

@Component({
  selector: 'sacc-activation-status',
  templateUrl: `./activation-status.component.html`,
  styleUrls: ['./activation-status.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule]
})
export class ActivationStatusComponent {
  @HostBinding('class.sacc-activation-status') public hostCls = true;
  @HostBinding('class') public statusClass = '';

  protected internalActivationStatus: AccountAccessStatus;

  @Input()
  public set activationStatus(value: AccountAccessStatus) {
    this.internalActivationStatus = value;
    this.statusClass = value.toLocaleLowerCase();
  }

  get activationStatusText(): string {
    return `COMPONENTS.ACTIVATION_STATUS.${this.internalActivationStatus?.toUpperCase()}`;
  }
}
