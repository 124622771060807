import { PaginationResult } from '@celum/core';

export class SaccPaginationResult extends PaginationResult {
  constructor(
    public hasBottom: boolean,
    public hasTop: boolean,
    public totalElementCount?: number,
    public filterCount?: number
  ) {
    super(hasBottom, hasTop, totalElementCount);
  }
}
