export enum SaccHttpHeaders {
  ERROR_KEY = 'errorKey'
}

export enum SaccHttpHeaderValues {
  USER_ALREADY_EXISTS = 'user.user-already-exists',
  ACCOUNT_ACCESS_ALREADY_MEMBER = 'user.access-request.already-member',
  ACCOUNT_ACCESS_ALREADY_EXISTING_PENDING_APPROVAL = 'user.access-request.already-existing-pending-approval',
  NO_SUCH_ASSOCIATED_ACCOUNT = 'repository.no-such-associated-account',
  TOKEN_EXPIRED = 'token-expired',
  MULTI_REPOSITORY_ASSOCIATION = 'repository.multi-repository-association'
}
