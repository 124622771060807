import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountAccessStatus, AccountUserRole, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { SaccProperties } from '@celum/sacc/domain';
import { ProfileComponent } from '@celum/sacc/ui';
import { SystembarComponent, SystembarConfiguration } from '@celum/shared/ui';

import { CollapseButtonComponent } from './collapse-button/collapse-button.component';

export interface LandingPageViewModel {
  adminLoggedIn: boolean;
  canOpenMyOrg: boolean;
  accountManager: boolean;
  canOpenUserGroups: boolean;
  canOpenAccountMembership: boolean;
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SystembarComponent,
    MatSidenavModule,
    ProfileComponent,
    CollapseButtonComponent,
    RouterOutlet,
    RouterLinkActive,
    RouterLink
  ]
})
export class LandingPageComponent {
  protected SaccProperties = SaccProperties;

  protected vm$: Observable<LandingPageViewModel> = this.userService.currentUser$.pipe(
    isTruthy(),
    map(currentUser => {
      const hasTrialPlan = !!currentUser.trialPlan && !!currentUser.trialPlan.accountRequestId;
      const accounts = currentUser.accountAccesses;
      const managedAccounts = accounts.filter(account => account.status === AccountAccessStatus.ACTIVE && account.role === AccountUserRole.MANAGER);
      const isAccountManager = managedAccounts.length > 0;
      const isAdmin = currentUser.admin;

      return {
        adminLoggedIn: isAdmin,
        canOpenMyOrg: isAccountManager || hasTrialPlan,
        accountManager: isAccountManager,
        canOpenUserGroups: managedAccounts.length > 0,
        canOpenAccountMembership: (isAccountManager || isAdmin) && managedAccounts.length > 0
      };
    })
  );

  protected systembarConfiguration: SystembarConfiguration = {
    itemProvider: () => []
  };

  constructor(private userService: UserService) {}
}
