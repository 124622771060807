import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { InvitationStatus } from '@celum/authentication';
import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { AccountUser } from '@celum/shared/domain';
import { UserAvatarComponent } from '@celum/shared/ui-people';

@Component({
  selector: 'sacc-email-row',
  templateUrl: './email-row.component.html',
  styleUrls: ['./email-row.component.scss'],
  standalone: true,
  imports: [MatIconModule, UserAvatarComponent, CelumIconModule]
})
export class EmailRowComponent implements OnInit {
  @Input()
  public email: string;
  @Output()
  public readonly remove = new EventEmitter<any>();

  protected user: Partial<AccountUser>;
  protected removeIconConfig = IconConfiguration.large('cancel-m').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(20);

  public ngOnInit(): void {
    this.user = {
      email: this.email,
      firstName: this.getInitials()[0],
      lastName: this.getInitials()[1],
      invitationStatus: InvitationStatus.INVITED
    };
  }

  protected onRemove(): void {
    this.remove.emit(true);
  }

  private getInitials(): string {
    if (!this.email) {
      return '-';
    }

    // Take the local part
    const localPart = this.email.split('@')[0];

    // If it contains dot
    if (localPart.includes('.')) {
      const parts = localPart.split('.');

      // If dot was splitting up 2 words, take first chars from both of them
      if (parts.length > 1) {
        return parts[0].slice(0, 1) + parts[1].slice(0, 1);
      }
    }

    // If it does not contain dot and has at least 2 letters, return first 2
    if (localPart.length > 1) {
      return localPart.slice(0, 2);
    }

    // Otherwise return just the first letter
    return localPart;
  }
}
